import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetGroupMutation } from "../../../store/slice/groupApiSlice";
import toast, { Toaster } from "react-hot-toast";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {refetch : Function, onClose:Function}
export const AddGroupPopup:React.FC<Props> = ({refetch, onClose}) => {
    const location = useLocation()
    // 그룹 명을 저장했는지 판단하기 위한 상태값
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const [isSave, setSave] = useState<boolean>(false)
    const category = new URLSearchParams(location.search).get('category')
    const navigate = useNavigate()
    const [setGroupApi] = useSetGroupMutation()
    const [groupName, setGroupName] = useState<string>('')
    const [groupId, setGroupId] = useState<any>(null)
    const [addMemberData, setAddMemberData] = useState<any>({categoryName : '', groupName : ''})
    const handleAddGroup = async() => {
        if(groupName === ''){
            toast.error(errorUndoButton, {style : errorStyle}); return;
        }else{
            const formData : any = new FormData();
            formData.append('managerId' , userInfo?.apiId)
            formData.append('categoryId', category)
            formData.append('groupName', groupName)
            const result:any = await setGroupApi(formData);
            if(result.data.result === true){
                refetch()
                setSave(true)
                // setAddMemberData((prev:any)=>({...prev, categoryName : result.data.categoryName, groupName : result.data.groupName}))
                setGroupId(result.data.groupId)
                setAddMemberData((prev:any)=>({...prev, categoryName : result.data.categoryName, groupName : result.data.groupName}))
                onClose()
            }else{alert(result.data.resultMsg)}
        }
    }
    const handleAddMember = () => {
        onClose()
        navigate(`/groups/addMember?category=${category}&groupId=${groupId}&check=N`, 
        {state : {categoryName : addMemberData.categoryName, groupName : addMemberData.groupName}})
    }
    return(
        <>
        <div className="alertPopUp popUp_active active">
            <div>
                <div>
                    <div className="editBox">
                        <input
                            className="group"
                            type="text"
                            name="groupName"
                            onChange={(e)=>setGroupName(e.target.value)}
                            id="test2"
                            placeholder="그룹명을 입력해 주세요."
                            required
                            readOnly={isSave ? true : false}
                        />
                        <label htmlFor="test2"></label>
                    </div>
                </div>
                {isSave ? 
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>건너뛰기</button>
                    <button className="blueBtn" onClick={handleAddMember}>회원추가</button>
                </div>
                :
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="blueBtn" onClick={handleAddGroup}>그룹생성</button>
                </div>
                }
            </div>
        </div>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />
        </>
    )
}

const successUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        그룹이 생성되었습니다.
    </span>
    </div>
);

const seccessStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid #F79E00',
}

const errorUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        그룹명을 입력해야 합니다.
    </span>
    </div>
);

const errorStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : 'black',
    backgroundColor : '#F79E000D',
    border : '1px solid red',
}