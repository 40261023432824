import React from "react";
import { useNavigate } from "react-router-dom";

export const MailSuccess = () => {
    const navigate = useNavigate()
    return(
        <>
        <div className="sentCompleted active">
            <div>
                <p>메일을 성공적으로 보냈습니다.</p>
                <div>
                    <button className="purpleBorderBtn" onClick={()=>navigate('/mailbox/sentMail?page=1&size=10&keyword=&column=mailSubject&order=desc')}>보낸 메일함</button>
                    <button className="purpleBtn" onClick={()=>window.location.reload()}>메일쓰기</button>
                </div>
            </div>
        </div>
        </>
    )
}