import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GroupTabBox } from "../../../components/group/groupTabBox";
import { GroupToolBox } from "../../../components/group/groupToolBox";
import { Paginate } from "../../../components/paginate/paginate";
import { GroupAddList } from "./category/add-list";
import { GroupList } from "./category/list";
import { AddGroupPopup } from "../../../components/group/popUp/AddGroupPopup";
import { CategoryManagementPopup } from "../../../components/group/popUp/categoryManagement";
import { AddCategoryPopup } from "../../../components/group/popUp/addCategoryPopup";
import { EditGroupPopup } from "../../../components/group/popUp/editGroupPopup";
import { useGetMemberListByCategoryMutation, useSetCategoryMutation, useSetMemberInGroupMutation } from "../../../store/slice/groupApiSlice";
import { GroupAddMemberTabBox } from "../../../components/group/groupAddMemberTabBox";
import toast, { Toaster } from "react-hot-toast";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {categoryList:any, getCategoryList:Function}
export const Group:React.FC<Props> = ({categoryList, getCategoryList}) => {
    const {groupName} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const userId = useAppSelector((state) => state.loginUser.userInfo.apiId)
    const categoryParam = new URLSearchParams(location.search).get('category');
    const groupId = new URLSearchParams(location.search).get('groupId');
    const page : any = new URLSearchParams(location.search).get('page') || 1;
    const size : any = new URLSearchParams(location.search).get('size') || 10;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const column : any = new URLSearchParams(location.search).get('column') || 'groupName';
    const order : any = new URLSearchParams(location.search).get('order') || 'desc';
    const [param, setParam] = useState<any>({categoryId : categoryParam, loginId : userId, page : page, size : size, keyword : '', sortColumn : column, sortOrder : order})
    const [getMemberByCategoryListApi] = useGetMemberListByCategoryMutation()
    const [setGroupApi] = useSetCategoryMutation()
    const [setMemberInGroup] = useSetMemberInGroupMutation()
    const [data, setData] = useState<any>([])
    const [pageInfo, setPageInfo] = useState<{className:string,totalCount:number}>({className : '', totalCount : 0})
    const [popUp, setPopup] = useState<{isOpen:boolean,type:string,groupInfo:any}>({isOpen:false,type:'',groupInfo:null})
    const [selectGroup, setSelectGroup] = useState<any>([])
    const [selectAddMember, setSelectAddMember] = useState<any>([])
    const handleOpen = (isOpen : boolean, type : string, groupInfo : any) => {setPopup((prev)=>({...prev, isOpen : isOpen, type : type, groupInfo : groupInfo}))}
    const handleClose = () => {setPopup({isOpen : false, type : '', groupInfo : null}); getCategoryList()}
    const handlePage = (pageNumber:number) => {
        if(parseInt(page) !== pageNumber){navigate(`/groups/group?category=${categoryParam}&page=${pageNumber}`)}
    }
    // 카테고리 추가
    const handleCategoryAdd = async(formData : any) => {
        const result : any = await setGroupApi(formData)
        if(result.data.result === true){handleClose();}else{alert(result.data.resultMsg)}
    }
    // 그룹별 회원 리스트
    const getMemberByCategoryList = async() => {
        if(groupName === 'group'){
            const result : any = await getMemberByCategoryListApi(param)
            if(result.data.result === true){setData(result.data.list); setPageInfo((prev:any)=>({...prev, totalCount : result.data.total_cnt}))}
            else{alert(result.data.resultMsg)}
        }
    }
    // 회원 그룹안에 추가하기 버튼
    const handleAddMember = async() => {
        if(selectAddMember?.length === 0){
            toast.error(errorUndoButton, {style : errorStyle}); return;
        }else{
            const formData : any = new FormData()
            formData.append('managerId', userId)
            formData.append('categoryId', categoryParam)
            formData.append('groupId', groupId)
            formData.append('memberId', selectAddMember)
            const result : any = await setMemberInGroup(formData)
            if(result.data.result === true){
                getMemberByCategoryList()
                navigate(`/groups/group?category=${categoryParam}`)
                toast.success(successUndoButton, {style : successStyle})
            }else{alert(result.data.resultMsg)}
        }
    }
    useEffect(()=>{setParam((prev:any)=>({...prev, categoryId : categoryParam, loginId : userId, page : page, size : size, keyword : keyword, sortColumn : column, sortOrder: order}))},
    [categoryParam, userId, page, size, keyword, column, order])
    useEffect(()=>{
        getMemberByCategoryList()
    }, [param.categoryId, param.loginId, param.page, param.size, param.keyword, param.sortColumn, param.sortOrder])
    useLayoutEffect(()=>{
        if(groupName === 'addMember'){setPageInfo((prev)=>({...prev, className : 'group_memberAdd'}))}
        else{setPageInfo((prev)=>({...prev, className : 'group'}))}
    }, [groupName])
    
    return(
        <>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />
        <div className={`contentBox group ${pageInfo.className}`}>
            <div>
                <h2>Group</h2>

                {groupName === 'addMember' ? 
                '' 
                :
                <GroupTabBox
                    category={categoryList} 
                    onOpen={handleOpen}
                />
                }
                {groupName === 'addMember'? '' :
                <GroupToolBox 
                    groupIdArray={selectGroup} 
                    setGroupIdArray={setSelectGroup}
                    onOpen={handleOpen}
                    refetch={getMemberByCategoryList}
                />
                }
                <div className="flexWrap">
                    {groupName === 'addMember'?

                    <GroupAddList
                        categoryParam={categoryParam}
                        groupId={groupId}
                        selectMember={selectAddMember}
                        setSelectMember={setSelectAddMember}
                    />

                    :
                    <>
                    <GroupList
                        data={data}
                        onOpen={handleOpen}
                        selectGroup={selectGroup}
                        setSelectGroup={setSelectGroup}
                    />

                    <div className="pagerBox">
                        <Paginate page={page} size={10} totalCount={pageInfo?.totalCount} onPage={handlePage}/>
                        {/* <button className="purpleBtn" onClick={handleAddMember}>회원 추가하기</button> */}
                    </div>
                    </>
                    }
                    
                </div>
            </div>
        </div>
        {/* 그룹 추가 팝업 */}
        {popUp.isOpen && popUp.type === 'addGroup' ? <AddGroupPopup refetch={getMemberByCategoryList} onClose={handleClose}/> : ''}
        {/* 그룹 수정 팝업 */}
        {popUp.isOpen && popUp.type === 'editGroup' ? <EditGroupPopup info={popUp} refetch={getMemberByCategoryList} onClose={handleClose}/> : ''}
        {/* 카테고리 추가 팝업 */}
        {popUp.isOpen && popUp.type === 'addCategory' ? <AddCategoryPopup onAdd={handleCategoryAdd} onClose={handleClose}/> : ''}
        {/* 카테고리 관리 팝업 */}
        {popUp.isOpen && popUp.type === 'cagetgoryManagement' ? <CategoryManagementPopup category={categoryList} onAdd={handleCategoryAdd} refetch={getCategoryList} onClose={handleClose}/> : ''}
        </>
    )
}

const successUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        회원을 추가하였습니다.
    </span>
    </div>
);

const successStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid #F79E00',
}

const errorUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        회원을 선택하셔야 합니다.
    </span>
    </div>
);

const errorStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid red',
}