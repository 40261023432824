import React, { useEffect, useRef, useState } from "react";
import Chips, { Chip } from "react-chips";
import { useLocation } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import { useGetSearchMemberMutation } from "../../store/slice/mailApiSlice";
import { useAppSelector } from "../../store/storeHooks";
export interface Props {getData : any, data : any, setData : Function}
export const RecevierBox:React.FC<Props> = ({getData, data, setData}) => {
    const location = useLocation()
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const membersParam : any = new URLSearchParams(location.search).get('members')
    const categorysParam : number | any = new URLSearchParams(location.search).get('categorys')
    const groupsParam : any = new URLSearchParams(location.search).get('groups')
    const [getSearchMember] = useGetSearchMemberMutation()
    const [groupBoxOpen, setOpen] = useState<boolean>(false)
    const [members , setMembers] = useState<any>([])
    const [categoryName, setCategoryName] = useState<string>('')
    
    console.log(getData?.memberList)
    const handleSearchMember = async(e:any) => {
        if (e.key && e.key.startsWith("Arrow"))return;
        if(!e.nativeEvent.isComposing){
            const result : any = await getSearchMember({managerId : userInfo?.apiId, searchKeyword : e.target.value})
            if(result.data.result === true){
                const formatData : any = result?.data?.memberList?.map((list:any) => ({
                    value : list?.memberId,
                    label : list?.memberName
                }))
                setMembers(formatData)
            }else{alert(result.data.resultMsg)}
        }
    }
    // 회원 선택 시
    const handleSelectMember = (e:any) => {
        const values = e.map((member: any) => member.value)
        console.log(values)
        setData((prev:any) => ({...prev,receiveMembers : values }))
    }

    // 카테고리 선택 시 : 카테고리 아이디 변경 & 그룹 배열 초기화
    const handleSelectCategory = (category:any) => {
        setData((prev:any)=>({...prev, receiveCategryId : category?.categoryId, receiveGroupId : []}))
    }
    
    // 그룹 선택 시
    const handleSelectGroup = (e:any) => {
        const {checked} = e.target;
        if(checked){
            setData((prev:any) => ({
                ...prev, receiveGroupId : [...prev.receiveGroupId, e.target.id]
            }))
        }else{
            setData((prev:any) => ({
                ...prev, receiveGroupId : prev.receiveGroupId.filter((id:any) => id !== e.target.id)
            }))
        }
    }
    
    // 받는 사람 유형이 달라질 때마다
    useEffect(()=>{
        if(membersParam){ // 선택 회원을 가지고 들어올 때
            setData((prev:any)=>({...prev, receiveMembers : membersParam.split(','), receiveClass : 'S'}))
        }
        else if(categorysParam){
            setData((prev:any)=>({...prev, receiveCategryId : categorysParam, receiveClass : 'G'}))
        }
        else{
            setData((prev:any)=>({...prev, receiveCategryId :0, receiveGroupId : [], receiveMembers : []}))
        }
        setOpen(false)
    }, [data?.receiveClass])

    // 카테고리 ID 가 존재할 때 , 해당 ID에 일치하는 Group 찾기
    const selectedGroup = getData?.categoryList?.find((x:any) => x?.categoryId === parseInt(data?.receiveCategryId))

    // 카테고리 및 그룹이 변할때마다
    useEffect(()=>{
        if(data?.receiveCategryId !== 0){
            const categoryName = getData?.categoryList?.find((x:any)=>x.categoryId === parseInt(data?.receiveCategryId))
            if(categoryName) setCategoryName(categoryName?.categoryName)
        }
    }, [data])
    return(
        <>
        <tr>
            <th>받는사람</th>
            <td>
                <div className="flexBox radioList">
                    <div className="radioBox" onClick={()=>setData((prev:any)=>({...prev, receiveClass : 'T'}))}>
                        <input type="radio" name="recipient" id="all" checked={data?.receiveClass === 'T' ? true : false}/>
                        <label htmlFor="all">회원전체</label>
                    </div>
                    <div className="radioBox" onClick={()=>setData((prev:any)=>({...prev, receiveClass : 'S'}))}>
                        <input type="radio" name="recipient" id="member_sel" checked={data?.receiveClass === 'S' ? true : false}/>
                        <label htmlFor="member_sel">회원선택</label>
                    </div>
                    <div className="radioBox" onClick={()=>setData((prev:any)=>({...prev, receiveClass : 'G'}))}>
                        <input type="radio" name="recipient" id="group_sel" checked={data?.receiveClass === 'G' ? true : false}/>
                        <label htmlFor="group_sel">그룹선택</label>
                    </div>
                </div>
                {data?.receiveClass === 'S' ?

                <div className="table_inputBox active">

                    <CreatableSelect
                        onKeyDown={(e)=>handleSearchMember(e)}
                        onChange={handleSelectMember}
                        value={data?.receiveMembers?.map((x:any)=>getData?.memberList?.find((y:any) => y.value === parseInt(x)))}
                        isMulti 
                        options={getData?.memberList}
                        placeholder={'회원을 선택해주세요.'}
                    />

                </div>

                : ''
                }
                {/* <!-- 회원선택 했을 때 활성화 --> */}
                
                {data?.receiveClass === 'G' ? 
                <div className="group selectBox active">
                    <div className="selectWrap">
                        {data?.receiveCategryId === 0 ? 
                        <button onClick={()=>setOpen(!groupBoxOpen)}>그룹 선택하기</button>
                        :
                        <button onClick={()=>setOpen(!groupBoxOpen)}>
                            {categoryName} ({data?.receiveGroupId?.length})
                        </button>
                        } 
                        <div className={groupBoxOpen ? 'active' : ''}>
                            <ul>
                                {getData?.categoryList?.map((category : any) => (
                                    <li className={category?.categoryId === parseInt(data?.receiveCategryId) ? 'active' : ''} key={category?.categoryId} onClick={()=>handleSelectCategory(category)}>
                                        <span>{category?.categoryName}</span>
                                        <div>
                                            {/* <span>0/4</span> */}
                                            <button onClick={()=>setData((prev:any)=>({...prev, receiveGroupId : []}))}>
                                                <i className="fa-regular fa-xmark"></i>
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                {selectedGroup && selectedGroup?.groupList?.map((group : any) => {
                                    const isChecked = data?.receiveGroupId?.includes(group?.groupId.toString())
                                    return(
                                        <>
                                        <li key={group?.groupId}>
                                            <div className="checkBox">
                                                <input 
                                                    type="checkbox" 
                                                    onChange={handleSelectGroup}
                                                    id={group?.groupId}
                                                    checked={isChecked}
                                                />
                                                <label htmlFor={group?.groupId}>{group?.groupName}</label>
                                            </div>
                                        </li>
                                        </>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                : ''
                }
                {/* <!-- 그룹선택 했을 때 활성화 --> */}
            </td>
        </tr>
        </>
    )
}