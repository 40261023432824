import React, { useState } from "react";
import { useSetSendorMutation } from "../../../store/slice/settingApiSlice";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {onClose : Function}
export const AddSendorPopup:React.FC<Props> = ({onClose}) => {
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const [setSendor] = useSetSendorMutation()
    const [data, setData] = useState<{name:string,email:string}>({name : '', email : ''})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setData((prev:any) => ({...prev, [name] : value}))
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        formData.append('managerId', userInfo?.apiId)
        formData.append('sendorName', data?.name)
        formData.append('sendorEmail', data?.email) 
        const result : any = await setSendor(formData)
        if(result.data.result === true){onClose()}else{alert(result.data.resultMsg)}
    }
    return(
        <>
        <div className="addPopUp popUp_active active">
            <div className="category">
                <div>
                    <div>
                        <button onClick={()=>onClose()}><i className="fa-regular fa-xmark"></i></button>
                        <h3>신규 대표메일</h3>
                    </div>
                    <div>
                        <div>
                            <div className="inputContainer">
                                <div>
                                    <label htmlFor="test">이름 <span>*</span></label>
                                    <input type="text" name="name" id="test" placeholder="이름을 입력해 주세요." onChange={handleChange} required/>
                                </div>
                                <div>
                                    <label htmlFor="">이메일 <span>*</span></label>
                                    <input type="text" name="email" id="" placeholder="이메일을 입력해 주세요." onChange={handleChange} required/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="blueBtn" onClick={handleSave}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}