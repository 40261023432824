import React from "react";
interface Props {
    onClose : Function
}
export const TemplatePreviewPopup:React.FC<Props> = ({onClose}) => {

    return(
        <>
        <div className="previewPopUp popUp_active active">
            <div>
                <button onClick={()=>onClose()}><i className="fa-regular fa-xmark"></i></button>
                <h3>타이틀을 입력해 주세요.</h3>
                <div className="top">
                    <span>받는 사람</span>
                    <span>회원 전체</span>
                </div>
                <div className="imgBox">
                    <img src="/assets/images/template_preview.png" alt="미리보기"/>
                </div>
            </div>
        </div>
        </>
    )
}