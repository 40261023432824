import React from "react";
import { useGetPreviewTemplateMutation } from "../../store/slice/mailApiSlice";
export interface Props {getData : any, data  :any, setData : Function}
export const TemplateBox:React.FC<Props> = ({getData, data, setData}) => {
    console.log(data)
    const [getPreviewTemplate] = useGetPreviewTemplateMutation()
    const handleNoneTemplate = () => {
        setData((prev:any) => ({...prev, template : 0}))
    }
    const handleChange = (e:any) => {
        const {name, value} = e.target
        setData((prev:any) => ({...prev, [name] : value}))
    }
    const previewTemplate = async(e : any, id : any) => {
        e.preventDefault();
        const responnse : any = await getPreviewTemplate({emailTemplateId : id})
        if(responnse?.data?.result === true) {
                const newWindow = window.open("", "_blank")
                if(newWindow){
                    newWindow.document.write(`
                    
                        <iframe srcDoc="${responnse?.data?.list[0]?.emailTemplate}" style="width:100%; height:100%; border:none;"></iframe>
                       
                    `);
                    newWindow.document.close();
                }
        }
    }

    return(
        <>
        <tr className="template">
            <th>템플릿</th>
            <td>
                <div className="flexBox">
                    <div>
                        <div className="thumbBox">
                            <img src="/assets/images/template_none.png" alt="사용안함"/>
                        </div>
                        <div className="radioBox" onClick={handleNoneTemplate}>
                            <input type="radio" value={0} name="templateId" id="" checked={data === 0 ? true : false}/>
                            <label htmlFor="">사용안함</label>
                        </div>
                    </div>
                    {/* <!-- 사용안함 --> */}
                    {getData?.map((list:any) => (
                        <div key={list?.templateId}>
                            <div className="thumbBox">
                                <a href="#" onClick={(e)=>previewTemplate(e , list?.templateId)} className="preview">미리보기</a>
                                <img src={list?.templateImage} alt="템플릿 썸네일"/>
                            </div>
                            <div className="radioBox">
                                <input
                                    type="radio"
                                    value={list?.templateId}
                                    onChange={handleChange}
                                    name={'templateId'}
                                    id={`template_${list?.templateId}`}
                                    checked={list?.templateId === parseInt(data) ? true : false}
                                />
                                <label htmlFor={`template_${list?.templateId}`}></label>
                            </div>
                        </div>
                    ))}
                    {/* <!-- 템플릿 선택 --> */}
                </div>
            </td>
        </tr>
        </>
    )
}
