import React, { useEffect, useState } from "react";
import * as XLSX from 'xlsx';
import { useGetCsvFileMutation } from "../../store/slice/membersApiSlice";
import { useAppSelector } from "../../store/storeHooks";
import { ExcelUploadProgressPopup } from "./popup/excelUploadProgressPopup";
export interface Props {
    refetch : Function
}
export const ExcelUpload:React.FC<Props> = ({refetch}) => {
    const userInfo = useAppSelector(state=>state.loginUser.userInfo)
    const [getCsvFile] = useGetCsvFileMutation()
    const [excelData, setExcelData] = useState<any>([]) // init excel data
    const [fomatData, setFomatData] = useState<any>([]) // fomated excel data
    const [requestCount, setRequestCount] = useState<number>(0) // api call count
    const [isActive, setActive] = useState<boolean>(false)
    const handleClose = () => {
        setActive(false); refetch()
    }
    // 파일 업로드
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActive(true)

        const file = e.target.files && e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const result = event.target?.result;
                if (typeof result === "string") {
                    const workbook = XLSX.read(result, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    setExcelData(data);
                }
            };
            reader.readAsBinaryString(file);
        }
    };

    const convertToFormData = (item : any) => {
        const formData : any = new FormData();
    
        Object.entries(item).forEach(([key, value]) => {
            formData.append(key, value);
        });
        formData.append("managerUuid", userInfo.userId);
        return formData;
    };
    
    const sendSequentialRequests = async () => {
        for (const item of fomatData) {
            const formData = convertToFormData(item);
            const result : any = await getCsvFile(formData);
            setRequestCount(prevCnt => prevCnt + 1)
        }
    };

    useEffect(()=>{
        sendSequentialRequests()
    }, [fomatData])

    useEffect(() => {
        if (excelData.length > 0) {
            const formattedData = excelData.slice(1).map((row: any) => ({
                tempName: row[0] === undefined ? '' : row[0],
                tempCompany : row[1] === undefined ? '' : row[1],
                tempEmail: row[2] === undefined ? '' : row[2],
                tempPhone: row[3] === undefined ? '' : row[3],
                tempMobile : row[4] === undefined ? '' : row[4],
                tempDept : row[5] === undefined ? '' : row[5],
                tempPosition : row[6] === undefined ? '' : row[6],
                tempAddress : row[7] === undefined ? '' : row[7],
                tempNote : row[8] === undefined ? '' : row[8],
            }));
            setFomatData(formattedData);
        }
    }, [excelData]);

    return(
        <>
        <div>
            <input type="file" name="" onChange={handleChange} id="excelUploadBtn"/>
            <label htmlFor="excelUploadBtn" className="excelUploadBtn">
                <span>엑셀 업로드하기</span>
            </label>
        </div>
        {
            isActive && 
            <ExcelUploadProgressPopup
                uploadCount={requestCount}
                totalCount={fomatData?.length}
                onClose={handleClose}
            />
        }
        </>
    )
}