import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://dotsmail.net/api/controller";

export const groupApiSlice = createApi({
  reducerPath: "groupApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 카테고리 저장
    setCategory : builder.mutation({
      query : (body : any) => {
        return{
          url : `/common/setCommon.php`, method : 'post', body
        }
      }
    }),
    // 카테고리 수정
    updCategory : builder.mutation({
      query : (body : any) => {
        return{
          url : '/common/updCategoryName.php', method : 'post', body
        }
      }
    }),
    // 카테고리 삭제
    delCategory : builder.mutation({
      query : (body : any) => {
        return{
          url : '/common/delCategory.php', method : 'post', body
        }
      }
    }),
    // 카테고리 별 회원리스트 가져오기
    getMemberListByCategory : builder.mutation({
      query : (param : {categoryId : any, loginId : any, page : any, size : number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/member/getMemberListByCategory.php?categoryId=${param.categoryId}&loginId=${param.loginId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}`
        }
      }
    }),
    // 그룹 생성
    setGroup : builder.mutation({
      query : (body : any) => {
        return{
          url : '/common/setGroup.php', method : 'post', body
        }
      }
    }),
    // 그룹별 상세보기
    getMemberListByGroup : builder.mutation({
      query : (param : {loginId : any, groupId : any}) => {
        return{
          url : `/member/getMemberListByGroup.php?loginId=${param.loginId}&groupId=${param.groupId}`
        }
      }
    }),
    // 그룹명 변경
    updGroupName : builder.mutation({
      query : (body : any) => {
        return{
          url : '/common/updGroupName.php', method : 'post', body
        }
      }
    }),
    // 그룹 삭제
    delGroup : builder.mutation({
      query : (body : any) => {
        return{
          url : '/common/delGroup.php', method : 'post', body
        }
      }
    }),
    // 그룹 내 회원추가를 위한 회원 리스트 ( 미소속 & 소속 회원 리스트 )
    getMemberAddToGroup : builder.mutation({
      query : (param : {loginId : any, page : any, size : number, keyword : string, sortOrder : string, sortColumn : string, memberCheckInGroup : string, categoryId : any, groupId : any}) => {
        return{
          url : `/member/getMemberAddToGroup.php?loginId=${param.loginId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&categoryId=${param.categoryId}&groupId=${param.groupId}&memberCheckInGroup=${param.memberCheckInGroup}`
        }
      }
    }),
    // 그룹 내 회원추가
    setMemberInGroup : builder.mutation({
      query : (body : any) => {
        return{
          url : '/member/setMemberInGroup.php', method: 'post', body
        }
      }
    }),
    // 그룹 내 회원삭제
    delMemberInGroup : builder.mutation({
      query : (body : any) => {
        return{
          url : '/member/delMemberInGroup.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useSetCategoryMutation, useUpdCategoryMutation, useGetMemberListByCategoryMutation, useGetMemberListByGroupMutation, useSetGroupMutation, useUpdGroupNameMutation, useDelMemberInGroupMutation,
  useDelGroupMutation, useSetMemberInGroupMutation, useGetMemberAddToGroupMutation, useDelCategoryMutation
} = groupApiSlice;