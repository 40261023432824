import React, { useState, useEffect } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import * as XLSX from 'xlsx';
import { Spinner } from "../../components/spinner/spinner";

export const Test = () => {
    
    const [excel, setExcel] = useState<any>([])
    const [fomatData , setFormatData] = useState<any>([])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const result = event.target?.result;
                
                if (typeof result === "string") {
                    const workbook = XLSX.read(result, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                    setExcel(data);
                }
            };
            
            reader.readAsBinaryString(file);
        }
    };

    useEffect(() => {
        if (excel.length > 0) {
            const formattedData = excel.slice(1).map((row: any) => ({
                tempName: row[0],
                tempEmail: row[1],
                tempPhone: row[2],
                tempMobile : row[3],
                tempDept : row[4],
                tempPosition : row[5],
                tempAddress : row[6],
                tempNote : row[7],
            }));
            
            setFormatData(formattedData);
        }
    }, [excel]);
    return (
        <>
           <input type="file" onChange={handleChange}/>
           <Spinner/>
        </>
    );
};