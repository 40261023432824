import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDelMemberInGroupMutation, useSetMemberInGroupMutation } from "../../store/slice/groupApiSlice";
import { useAppSelector } from "../../store/storeHooks";
export interface Props{refetch : Function, selectMember : any, setSelectMember : Function}
export const AddMemberToolBox:React.FC<Props> = ({refetch, selectMember, setSelectMember}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const searchRef = useRef<any>(null)
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const category = new URLSearchParams(location.search).get('category')
    const groupId = new URLSearchParams(location.search).get('groupId')
    const checkType = new URLSearchParams(location.search).get('type')
    const page : any = new URLSearchParams(location.search).get('page')||1;
    const size : any = new URLSearchParams(location.search).get('size')||10;
    const keyword : any = new URLSearchParams(location.search).get('keyword')||'';
    const column : any = new URLSearchParams(location.search).get('column');
    const order : any = new URLSearchParams(location.search).get('order');
    const [setMemberInGroup] = useSetMemberInGroupMutation()
    const [delMemberInGroup] = useDelMemberInGroupMutation()
     // 그룹 수정 : 회원추가
     const handleAddMember = async() => {
        const formData:any = new FormData()
        formData.append('managerId', userInfo?.apiId)
        formData.append('categoryId', category)
        formData.append('groupId', groupId)
        formData.append('memberId', selectMember)
        const result : any = await setMemberInGroup(formData)
        if(result.data.result === true){refetch();setSelectMember([])}
        else{alert(result.data.resultMsg)}
    }
    // 그룹 수정 : 회원제거
    const handleDelMember = async() => {
        const formData : any = new FormData()
        formData.append('managerId', userInfo?.apiId)
        formData.append('memberId', selectMember)
        formData.append('categoryId', category)
        formData.append('groupId', groupId)
        const result : any = await delMemberInGroup(formData)
        if(result.data.result === true){refetch();setSelectMember([])}
        else{alert(result.data.resultMsg)}
    }
    // 그룹 수정 : 그룹 회원 다르게 보이기
    const handleChangeCheckType = (check : string) => {
        if(searchRef.current){searchRef.current.value = ''}
        if(checkType !== check){
            navigate(`${location.pathname}?category=${category}&groupId=${groupId}&type=${check}&page=1&size=10&keyword=&column=memberName&order=asc`)
        }
    }
    // 검색
    const handleSearch = (e:any) => {
        if(e.key === 'Enter'){
            navigate(`${location.pathname}?category=${category}&groupId=${groupId}&type=${checkType}&page=${page}&size=${size}&keyword=${searchRef.current.value}&column=${column}&order=${order}`)
        }
    }
    return(
        <>
        <div className="toolBox">
            <div className="left">
                <div className="selectBox">
                    <select name="" id="" value={size} onChange={(e)=>navigate(`${location.pathname}?category=${category}&groupId=${groupId}&type=${checkType}&page=${page}&size=${e.target.value}&keyword=${keyword}&column=${column}&order=${order}`)}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                    </select>
                </div>
                <div className="txtBox">
                    <div>
                        <div className={`active_${checkType === 'Y' ? '1' : '2'}`}>
                            <div onClick={()=>handleChangeCheckType('Y')}>
                                <input type="radio" name="test" id="test1" />
                                <label htmlFor="test1">소속회원</label>
                            </div>
                            <div onClick={()=>handleChangeCheckType('N')}>
                                <input type="radio" name="test" id="test2" />
                                <label htmlFor="test2">미소속회원</label>
                            </div>
                        </div>
    
                        <div className="btnBox">
                            <button className="remove" onClick={handleDelMember}>
                                <span>회원 제거하기</span>
                            </button>
                            <button className="add" onClick={handleAddMember}>
                                <span>회원 추가하기</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right">
                <div className="selectBox">
                    <select name="" id="">
                        <option value="">Name</option>
                    </select>
                </div>
                <div className="searchBox">
                    <div>
                        <span></span>
                        <input ref={searchRef} defaultValue={keyword} onKeyDown={handleSearch} type="text" placeholder="Search..."/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}