import React from "react";
import { useDelMemberMutation } from "../../../store/slice/membersApiSlice";
import { toast, Toaster } from "react-hot-toast";
export interface Props {delId : any, getList:Function, onClose : Function}
export const DelMemberPopup:React.FC<Props> = ({delId, getList, onClose}) => {
    const [delMemberApi] = useDelMemberMutation()
    const handleDelete = async() => {
        const formData = new FormData();
        formData.append('memberId', delId)
        const result : any = await delMemberApi(formData)
        if(result.data.result === true){
            toast.success(successUndoButton, {style : seccessStyle})
            getList(); onClose();
        }else{alert(result.data.resultMsg)}
    }
    return(
        <>
        <div className="alertPopUp popUp_active active">
            <div>
                <div>
                    <p>
                        영구삭제 시 지워진 회원정보는 복구할 수 없습니다.
                        <strong>정말로 선택한 회원을 삭제하시겠습니까?</strong>
                    </p>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="redBtn" onClick={handleDelete}>삭제</button>
                </div>
            </div>
        </div>
        <Toaster
        position="bottom-right"
        reverseOrder={false}/>
        </>
    )
}

const successUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        영구 삭제되었습니다.
    </span>
    </div>
);

const seccessStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid #F79E00',
}