import React from "react";

export const MailProgressPopup = () => {

    return(
        <>
        <div className="progressPopUp popUp_active">
            <div className="excelUpload">
                <div>
                    <div>
                        <h3>메일 전송</h3>
                        <div className="progressBox">
                            <div>
                                <div>
                                    <span className="purple">8명</span>
                                    <span>32명</span>
                                </div>
                                <div>
                                    <span>전송속도 00.0MB/초</span>
                                    <span>8초 남음</span>
                                </div>
                            </div>
                            <div>
                                <span className="numb">25%</span>
                                <span className="progress" style={{width : '25%'}}></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="cancleBtn">취소</button>
                    <button className="yellowBtn">일시정지</button>
                </div>
            </div>
        </div>
        </>
    )
}