import React from "react";
import { useLocation } from "react-router-dom";
import { TemplateList } from "./list";
import { TemplateAdd } from "./add";

export const Template = () => {
    const location = useLocation()
    return(
        <>
        <div className={location.pathname === '/template' ? 'contentBox template' : 'contentBox template add'}>
            {location.pathname === '/template' ? 
            <TemplateList/> :
            <TemplateAdd/>
            }
        </div>
        </>
    )
}