import React from "react";

export const ReserveCancelPopup = () => {

    return(
        <>
        <div className="alertPopUp popUp_active">
            <div>
                <div>
                    <p>
                        메일을 수정하기 위해 예약발송을 취소하셔야 합니다.
                        <strong>선택한 메일의 예약발송을 취소하시겠습니까?</strong>
                    </p>
                </div>
                <div>
                    <button className="cancleBtn">취소</button>
                    <button className="purpleBtn">확인</button>
                </div>
            </div>
        </div>
        </>
    )
}