import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MemberToolBox } from "../../../components/member/memberToolBox";
import { DelMemberPopup } from "../../../components/member/popup/delMemberPopup";
import { EditMemberPopup } from "../../../components/member/popup/editMemberPopup";
import { NewMmemberPopup } from "../../../components/member/popup/newMemberPopup";
import { Paginate } from "../../../components/paginate/paginate";
import { useGetDelMemberListMutation, useGetMemberCategoryMutation, useGetMemberListMutation } from "../../../store/slice/membersApiSlice";
import { MemberList } from "./category/list";
import { TabBox } from "../../../components/item/tabBox";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
export interface MemberPageInfo {className : string, totalCount : number}
export interface PopupType {isOpen : boolean, type : string, memberInfo : any}
interface ParamType {loginId : any , page : any, size : number, keyword : string, sortColumn : string, sortOrder : string}
export interface Props {categoryList : any , getCategoryList : Function}
export const Member:React.FC<Props> = ({categoryList, getCategoryList}) => {
    const {category} = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const userId = useAppSelector((state) => state.loginUser.userInfo.apiId)
    console.log(userId)
    const page : any = new URLSearchParams(location.search).get('page') || 1;
    const size : any = new URLSearchParams(location.search).get('size') || 10;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const order : any = new URLSearchParams(location.search).get('order') || 'desc';
    const column : any = new URLSearchParams(location.search).get('column') || 'memberName';
    const [getMemberList] = useGetMemberListMutation()
    const [getDelMemberListApi] = useGetDelMemberListMutation()
    const [getCategoryListApi] = useGetMemberCategoryMutation()
    const [param, setParam] = useState<ParamType>({loginId : userId, page: page, size : size, keyword : keyword, sortColumn : column, sortOrder : order})
    const [data, setData] = useState<any>([])
    const [categoryFilter, setCategoryFilter] = useState<any>({group : [], industry : [], exhibition : [], area : []})
    const [totalCount, setTotalCount] = useState<number>(0)
    // 각 리스트에서 선택한 유저, 그룹을 담는 상태 값
    const [selectMember, setSelectMember] = useState<any>([])
    const [popUp, setPopup] = useState<PopupType>({isOpen : false, type : '', memberInfo : null})
    const handleOpen = (isOpen : boolean, type : string, memberInfo : any) =>{setPopup({isOpen : isOpen, type : type, memberInfo : memberInfo})}
    const handleClose = () => {setPopup({isOpen : false, type : '', memberInfo : null})}
    const [pageInfo, setPageInfo] = useState<MemberPageInfo>({className : '', totalCount : 0})
    const handlePage = (pageNumber:number) => {
        if(page !== pageNumber)
        navigate(`${location.pathname}?page=${pageNumber}&size=${size}&keyword=${keyword}&column=${column}&order=${order}`)
    }
    const getList = async() => {
        if(category === 'individual'){
            const result : any = await getMemberList(param)
            if(result.data.result === true){setData(result.data.list); setTotalCount(result.data.total_cnt)}
        }else{
            const result : any = await getDelMemberListApi(param)
            if(result.data.result === true){setData(result.data.list); setTotalCount(result.data.total_cnt)}
        }
    }
    useEffect(()=>{
        const groupData = categoryList?.filter((item : any) => item.idx === 1)
        const industryData = categoryList?.filter((item:any)=> item.idx === 2)
        const exhibitionData = categoryList?.filter((item:any)=> item.idx === 3)
        const areaData = categoryList?.filter((item:any)=> item.idx === 4)
        if(groupData){setCategoryFilter((prev:any)=>({...prev, group : groupData}))}
        if(industryData){setCategoryFilter((prev:any)=>({...prev, industry : industryData}))}
        if(exhibitionData){setCategoryFilter((prev:any)=>({...prev, exhibition : exhibitionData}))}
        if(areaData){setCategoryFilter((prev:any)=>({...prev, area : areaData}))}
    }, [categoryList])
    useEffect(()=>{setParam((prev)=>({...prev, loginId : userId, page : page, size : size, keyword : keyword, sortOrder : order, sortColumn : column}))}, [userId, page, size, keyword, order, column])
    useEffect(()=>{
        if(param.loginId){
            getList()
        }
    },[category, param.loginId, param.page, param.size, param.keyword, param.sortColumn, param.sortOrder])
    useEffect(()=>{
        if(category === 'individual'){getCategoryList()}
    }, [category])
    useLayoutEffect(()=>{
        switch(category){
            case 'individual' : setPageInfo((prev)=>({...prev, className : 'memberList'})); break;
            case 'deleted' : setPageInfo((prev)=>({...prev, className : 'deleted_member'})); break;
            default : setPageInfo((prev)=>({...prev, className : 'memberList'})); break;
        }
    }, [category])
    return(
        <>
        <div className={`contentBox member ${pageInfo.className}`}>
            <div>
                <h2>Member</h2>
                <TabBox/>
                <MemberToolBox getList={getList} selectedMember={selectMember} setSelectMember={setSelectMember} isOpen={handleOpen}/>
                <div className="flexWrap">
                    <div className='tabContentBox'>
                        <div className={pageInfo.className}>
                            <MemberList
                                data={data}
                                getList={getList}
                                selectMember={selectMember}
                                setMember={setSelectMember}
                                isOpen={handleOpen}
                            />
                            <div className="pagerBox">
                                <Paginate page={page} size={size} totalCount={totalCount} onPage={handlePage}/>
                                <button className="purpleBtn">회원 추가하기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {popUp.isOpen && popUp.type === 'newMember' ? <NewMmemberPopup category={categoryFilter} getList={getList} onClose={handleClose}/> : ''}
        {popUp.isOpen && popUp.type === 'editMember' ? <EditMemberPopup getList={getList} category={categoryFilter} info={popUp} onClose={handleClose}/> : ''}
        {popUp.isOpen && popUp.type === 'permanentlyDelete' ? <DelMemberPopup delId={selectMember} getList={getList} onClose={handleClose}/> : ''}
        </>
    )
}