import { Editor } from "@toast-ui/react-editor";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import '@toast-ui/editor/dist/toastui-editor.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
export interface Props {initData : string, name : string,  setData : Function}
export const TemplateCreateEditor:React.FC<Props> = ({initData, name, setData}) => {
    const editorRef = useRef<any>(null)
    const handleChange = () => {
        setData((prev:any)=>({...prev, [name] : editorRef.current.getInstance().getMarkdown()}))
    }
    useEffect(()=>{
        const editorInstance = editorRef.current.getInstance();
        if (initData && editorInstance.getMarkdown() !== initData) {
            editorInstance.setMarkdown(initData);
        }
    }, [initData])
    
    return(
        <>
        <Editor
            ref={editorRef}
            initialValue={initData}
            onChange={handleChange}
            previewStyle="vertical"
            height="300px"
            initialEditType="markdown"
            hideModeSwitch={true}
            useCommandShortcut={false}
            plugins={[colorSyntax]}
            // hooks={{ addImageBlobHook: onUploadImage,
            // }}
        />
        </>
    )
}