import React, { useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export interface Props {onOpen : Function, keyword : any}
export const MailBoxTab:React.FC<Props> = ({onOpen, keyword}) => {
    const {category} = useParams()
    const searchRef = useRef<any>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const handleTransTab = (url : string) => {
        if(location.pathname!==url){navigate(url)}
    }
    const handleSearch = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter') navigate(`${location.pathname}?page=1&size=10&keyword=${searchRef.current.value}&column=mailSubject&order=desc`)
    }
    return(
        <>
        <h2>Sent mailbox</h2>
        <div className="tabBtnBox">
            <button className={category==='sentMail'?'active':''} onClick={()=>handleTransTab('/mailbox/sentMail?page=1&size=10&keyword=&column=mailSubject&order=desc')}>Sent Mail</button>
            <button className={category==='deletedMail'?'active':''} onClick={()=>handleTransTab('/mailbox/deletedMail?page=1&size=10&keyword=&column=mailSubject&order=desc')}>Deleted Mail</button>
        </div>

        <div className="toolBox">
            <div className="left">
                <button className="deleteBtn">
                    <span>삭제</span>
                </button>
                <button className="permanentDeleteBtn" onClick={()=>onOpen(true, 'deleted')}>
                    <span>영구삭제</span>
                </button>
                <button className="restoreBtn">
                    <span>복원</span>
                </button>
            </div>
            <div className="right">
                <div className="searchBox">
                    <div>
                        <span></span>
                        <input type="text" ref={searchRef} defaultValue={keyword} onKeyDown={handleSearch} placeholder="Search..."/>
                    </div>
                </div>
                <button className="writingMailBtn_white" onClick={()=>navigate('/mails')}>
                    <span>메일쓰기</span>
                </button>
            </div>
        </div>
        </>
    )
}