import React from "react";
import { useUpdMemberActiveStatusMutation } from "../../store/slice/membersApiSlice";
export interface Props {name : string, id : any, status : string, onChange : Function}
export const ToggleBox:React.FC<Props> = ({name, id, status, onChange}) => {
    
    return(
        <>
            <span className="toggleSwitch">
                <input type="checkbox" id={`toggle_${name}_${id}`} className="toggleIpt" onChange={()=>onChange(id, status)} checked={status === 'Y' ? true : false} hidden/> 
                <label htmlFor={`toggle_${name}_${id}`} className="toggleSwitch">
                    <span className="toggleButton"></span>
                </label>
            </span>
        </>
    )
}