import { BarLoader } from "react-spinners";
export const Spinner = () => {
    return (
      <>
        <div style={{
            position: 'fixed', 
            top: 0,left: 0,
            width: '100%', height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 9999,}}
        >
          <h3 style={{marginBottom: '20px', color : '#000'}}>메일 전송 중입니다.</h3>
          <BarLoader color="#000" />
        </div>
      </>
    );
  };