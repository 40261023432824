import { ResultType } from "@remix-run/router/dist/utils";
import React, { useState } from "react";
import { useSetMemberMutation } from "../../../store/slice/membersApiSlice";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {getList : Function, onClose : Function, category : any}
export interface DataType {name:string,company : string,email:string,mobile:string,phone:string,dept:string,position:string,group:number,industry:number, exhibition:number, area:number, address:string, note:string, agree : ''}
export const NewMmemberPopup:React.FC<Props> = ({getList, onClose, category}) => {
    const userInfo = useAppSelector(state => state.loginUser.userInfo)
    const [setMemberApi] = useSetMemberMutation()
    const [data, setData] = useState<DataType>({name : '', company : '', email : '', mobile : '', phone : '', dept : '', position : '', group : 0, industry : 0, exhibition : 0, area : 0, address : '', note : '', agree : ''})
    const handleChange = (e:any) => {
        const {checked, name, value, type} = e.target;
        if(type === 'checkbox'){
            if(checked){setData((prev)=>({...prev, [name] : 'Y'}))}
            else{setData((prev)=>({...prev, [name] : 'N'}))}
        }else{
            setData((prev)=>({...prev, [name] : value}))
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        formData.append('managerUuid', userInfo?.userId)
        formData.append('memberName', data?.name)
        formData.append('memberCompany', data?.company)
        formData.append('memberEmail', data?.email)
        formData.append('memberPhone', data?.phone)
        formData.append('memberMobile', data?.mobile)
        formData.append('memberDept', data?.dept)
        formData.append('memberPosition', data?.position)
        formData.append('memberGroup', `1:${data?.group},2:${data?.industry},3:${data?.exhibition},4:${data?.area}`)
        formData.append('memberAddress', data?.address)
        formData.append('memberNote', data?.note)
        const result : any = await setMemberApi(formData)
        if(result.data.result === true){
            alert("등록이 완료되었습니다."); getList(); onClose()
        }else{alert(result.data.resultMsg)}
    }
    return(
        <>
        <div className="userInfo_popUp popUp_active active">
            <button className="closeBtn" onClick={()=>onClose()}>
                <i className="fa-light fa-xmark"></i>
            </button>
            <div className="btnBox">
                <div className="userAddBox">
                    <button className="userAddBtn" onClick={handleSave}>
                        <span>회원 등록하기</span>
                    </button>
                </div>
                
            </div>
            <div className="userInfoBox active">
                <div>
                    <div className="userNameBox">
                        <div>
                            <span>신규회원</span>
                        </div>
                    </div>

                    <ul className="add">
                        <li className="active">
                            <span>이름</span>
                            <input type="text" name="name" onChange={handleChange} id="" placeholder="이름을 입력해 주세요."/>
                        </li>
                        <li className="active">
                            <span>회사명</span>
                            <input type="text" name="company" onChange={handleChange} id="" placeholder="회사명을 입력해 주세요."/>
                        </li>
                        <li>
                            <span>이메일</span>
                            <input type="text" name="email" onChange={handleChange} id="" placeholder="이메일을 입력해 주세요."/>
                        </li>
                        <li>
                            <span>연락처</span>
                            <input type="text" name="mobile" onChange={handleChange} id="" placeholder="하이픈(-)을 제외한 번호를 입력해 주세요."/>
                        </li>
                        <li>
                            <span>핸드폰 번호</span>
                            <input type="text" name="phone" onChange={handleChange} id="" placeholder="하이픈(-)을 제외한 번호를 입력해 주세요."/>
                        </li>
                        <li>
                            <span>부서</span>
                            <input type="text" name="dept" onChange={handleChange} id="" placeholder="부서명을 입력해 주세요."/>
                        </li>
                        <li>
                            <span>직책</span>
                            <input type="text" name="position" onChange={handleChange} id="" placeholder="직책을 입력해 주세요."/>
                        </li>
                        <li className="selectWrap">
                            <span>Group</span>
                            <div className="selectBox">
                                <select name="group" onChange={handleChange} id="">
                                    <option value="0" disabled selected>그룹을 선택해 주세요.</option>
                                    {category?.group[0]?.groupList?.map((item : any) => (
                                        <option value={item?.groupIdx}>{item?.groupName}</option>
                                    ))}
                                </select>
                            </div>
                        </li>
                        <li className="selectWrap">
                            <span>Industry</span>
                            <div className="selectBox">
                                <select name="industry" onChange={handleChange} id="">
                                    <option value="0" disabled selected>산업군을 선택해 주세요.</option>
                                    {category?.industry[0]?.groupList?.map((item : any) => (
                                        <option value={item?.groupIdx}>{item?.groupName}</option>
                                    ))}
                                </select>
                            </div>
                        </li>
                        <li className="selectWrap">
                            <span>Exhibition</span>
                            <div className="selectBox">
                                <select name="exhibition" onChange={handleChange} id="">
                                    <option value="0" disabled selected>전시회를 선택해 주세요.</option>
                                    {category?.exhibition[0]?.groupList?.map((item : any) => (
                                        <option value={item?.groupIdx}>{item?.groupName}</option>
                                    ))}
                                </select>
                            </div>
                        </li>
                        <li className="selectWrap">
                            <span>Area</span>
                            <div className="selectBox">
                                <select name="area" onChange={handleChange} id="">
                                    <option value="0" disabled selected>지역을 선택해 주세요.</option>
                                    {category?.area[0]?.groupList?.map((item : any) => (
                                        <option value={item?.groupIdx}>{item?.groupName}</option>
                                    ))}
                                </select>
                            </div>
                        </li>
                        <li>
                            <span>Address</span>
                            <input type="text" name="address" onChange={handleChange} id="" placeholder="주소를 입력해 주세요."/>
                        </li>
                        <li>
                            <span>Note</span>
                            <input type="text" name="note" onChange={handleChange} id="" placeholder="메모를 기입해 주세요."/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}