import React, { useState } from "react";
import { MainBtnBox } from "../mainBtnBox";
import { MainFooter } from "../mainFooter";
interface FindPwData {email : string, phone : string}
export const FindPw = () => {
    const [data, setData] = useState<FindPwData>({email : '', phone : ''})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setData((prev)=>({...prev, [name] : value}))
    }
    return(
        <>
        <div className="loginWrap find">
            <div className="loginBox">
                <div>
                    <div className="left">
                        <span>Mail To :)</span>
                    </div>
                    {/* <!-- left Fin --> */}
                    <div className="right">
                        <div>
                            <h2>비밀번호 찾기</h2>
                            <span>회원가입 시 등록하신 아이디와 연락처를 입력해 주세요.</span>
                            <div>
                                <div className="inputBox">
                                    <label htmlFor="">이메일</label>
                                    <input type="text" name="email" value={data?.email} id="" onChange={handleChange} placeholder="이메일을 입력해 주세요." required/>
                                </div>
                                <div className="inputBox">
                                    <label htmlFor="">휴대전화</label>
                                    <input type="text" name="phone" value={data?.phone} id="" onChange={handleChange} placeholder="하이픈(-)을 제외한 전화번호를 입력해 주세요." required/>
                                </div>
                                <button className="purpleBtn">비밀번호 찾기</button>
                                <MainBtnBox/>
                            </div>
                        </div>
                    </div>
                    {/* <!-- right Fin --> */}
                </div>
                {/* <!-- flexBox Fin --> */}
                <MainFooter/>
            </div>
        </div>
        </>
    )
}