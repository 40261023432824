import React, { useEffect, useRef, useState } from "react";
import { CategoryManagementPopup } from "./popUp/categoryManagement";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {category : any, onOpen : Function}
export const GroupTabBox:React.FC<Props> = ({category, onOpen}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const categoryParam:any = new URLSearchParams(location.search).get('category')
    const groupName = new URLSearchParams(location.search).get('groupName')
    const closeRef = useRef<any>(null)
    const [state, setState] = useState<boolean>(false)
    const handleChangeTab = (idx : number) => {
        if(idx !== parseInt(categoryParam)){
            if(!groupName){navigate(`${location.pathname}?category=${idx}`)}
            else{navigate(`${location.pathname}?category=${idx}&groupName=${groupName}`)}
        }
    }
    useEffect(()=>{
        const handleOutSide = (e:any) =>{
            if(closeRef.current&&!closeRef.current.contains(e.target)){
                setState(false);
            }
        }
        document.addEventListener('mousedown', handleOutSide);
        return()=> {
            document.removeEventListener('mousedown', handleOutSide)
        }
    }, [])
    return(
        <>
        <div className="tabBtnBox">
            {category?.map((tab:any)=>(
                <button className={tab?.idx === parseInt(categoryParam) ? 'active' : ''} onClick={()=>handleChangeTab(tab?.idx)}>
                    {tab?.categoryName}
                </button>
            ))}
            <div>
                <button className="addBtn" onClick={()=>setState(prev=>!prev)}></button>
                {state &&
                <div ref={closeRef}>
                    <button onClick={()=>onOpen(true, 'cagetgoryManagement')}>카테고리 관리</button>
                    <button onClick={()=>onOpen(true, 'addCategory')}>새 카테고리 추가</button>
                </div>
                }
            </div>
        </div>
        </>
    )
}