import React, { useState } from "react";
import { useAppSelector } from "../../../store/storeHooks";
import { useSetManagerMutation } from "../../../store/slice/settingApiSlice";
interface DataType {name : string, email : string, mobile : string}
export interface Props {id : any ,onClose : Function}
export const AddEmployeePopup:React.FC<Props> = ({id, onClose}) => {
    const userInfo : any = useAppSelector((state)=>state.loginUser.userInfo)
    const [setManager] = useSetManagerMutation()
    const [data, setData] = useState<DataType>({name : '', email : '', mobile : ''})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setData((prev)=>({...prev, [name] : value}))
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        formData.append('managerId', userInfo?.apiId)
        formData.append('managerName', data?.name)
        formData.append('managerEmail', data?.email)
        formData.append('managerMobile', data?.mobile)
        const res : any = await setManager(formData)
        if(res.data.result === true) {
            alert('등록이 완료되었습니다.'); onClose()
        }else{alert(res.data.resultMsg)}
    }
    return(
        <>
        <div className="addPopUp popUp_active active">
            <div className="category">
                <div>
                    <div>
                        <button><i className="fa-regular fa-xmark" onClick={()=>onClose()}></i></button>
                        {id ? 
                        <h3>직원 수정</h3>
                        :
                        <h3>신규 직원</h3>
                        }
                    </div>
                    <div>
                        <div>
                            <div className="inputContainer">
                                <div>
                                    <label htmlFor="name">이름 <span>*</span></label>
                                    <input type="text" name="name" id="name" onChange={handleChange} placeholder="이름을 입력해 주세요." required/>
                                </div>
                                <div>
                                    <label htmlFor="email">이메일 <span>*</span></label>
                                    <input type="text" name="email" id="email" onChange={handleChange} placeholder="이메일을 입력해 주세요." required/>
                                </div>
                                <div>
                                    <label htmlFor="mobile">연락처</label>
                                    <input type="text" name="mobile" id="mobile" onChange={handleChange} placeholder="연락처를 입력해 주세요." required/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="blueBtn" onClick={()=>handleSave()}>등록</button>
                </div>
            </div>
        </div>
        </>
    )
}