import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useUpdGroupNameMutation } from "../../store/slice/groupApiSlice";
import { useAppSelector } from "../../store/storeHooks";
export interface Props {refetch:Function, name : any}
export const GroupAddMemberTabBox:React.FC<Props> = ({refetch, name}) => {
    const nameRef = useRef<any>(null)
    const location = useLocation()
    const groupId = new URLSearchParams(location.search).get('groupId')
    const stateName = location.state
    const userInfo = useAppSelector((state) => state.loginUser.userInfo)
    const [isActive, setActive] = useState<boolean>(false)
    const [updGroupName] = useUpdGroupNameMutation()
    const [categoryAndGroupName,setName] = useState<any>({categoryName : '', groupName : ''})
    const handleCHangeGroupName = async(e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter'){
            const formData : any = new FormData()
            formData.append('managerId', userInfo?.apiId)
            formData.append('groupId', groupId)
            formData.append('groupName', nameRef.current.value)
            const result : any = await updGroupName(formData)
            if(result.data.result === true){setActive(false); refetch()}
            else{alert(result.data.resultMsg)}
        }
    }
    useEffect(()=>{
        if(name){
            setName({categoryName : name.categoryName, groupName : name.groupName})
        }
    }, [name])

    return(
        <>
        <div className="tabBtnBox">
            <button className={'active'}>
                
            </button>
        </div>
        <div className="titleBox">
            <div>
                {!stateName?.categoryName ?
                <span className="category">[{categoryAndGroupName?.categoryName}] </span>:
                <span className="category">[{stateName?.categoryName}] </span>
                }
                <div className={isActive ? 'active' : ''}>
                    <input type="text" name="" id="" ref={nameRef} defaultValue={stateName?.groupName ? stateName.groupName : categoryAndGroupName?.groupName} onKeyDown={(e)=>handleCHangeGroupName(e)}/>

                    {!stateName?.groupName ? 
                    <span onClick={()=>setActive(true)}>{categoryAndGroupName?.groupName}</span>:
                    <span onClick={()=>setActive(true)}>{stateName?.groupName}</span>
                    }
                </div>
            </div>
        </div>
        </>
    )
}