import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export interface Props {data : any, selectedMember : any, onAllCheck : Function}
export const MemberListFilter:React.FC<Props> = ({data, selectedMember, onAllCheck}) => {
    const {category, settingName, groupName} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [filter, setFilter] = useState<any>([])
    const page : any = new URLSearchParams(location.search).get('page')||1;
    const size : any = new URLSearchParams(location.search).get('size')||10;
    const keyword : any = new URLSearchParams(location.search).get('keyword')||'';
    const column : any = new URLSearchParams(location.search).get('column');
    const order : any = new URLSearchParams(location.search).get('order');
    // group & addMember ---------
    const categoryParam : any = new URLSearchParams(location.search).get('category')
    const groupId : any = new URLSearchParams(location.search).get('groupId')
    const type : any = new URLSearchParams(location.search).get('type')
    // ---------------------------
    const [allCheck, setAllCheck] = useState<boolean>(false)
    const handleSort = (selColumn : string) => {
        if(groupName === 'group'){
            if(selColumn !== ''){
                if(column === selColumn){
                    navigate(`${location.pathname}?category=${categoryParam}&page=${page}&size=${size}&keyword=${keyword}&column=${selColumn}&order=${order === 'desc' ? 'asc' : 'desc'}`)
                }else{
                    navigate(`${location.pathname}?category=${categoryParam}&page=${page}&size=${size}&keyword=${keyword}&column=${selColumn}&order=desc`)
                }
            }
        }
        else if(groupName === 'addMember'){
            if(selColumn !== ''){
                if(column === selColumn){
                    navigate(`${location.pathname}?category=${categoryParam}&groupId=${groupId}&type=${type}&page=${page}&size=${size}&keyword=${keyword}&column=${selColumn}&order=${order === 'desc' ? 'asc' : 'desc'}`)
                }else{
                    navigate(`${location.pathname}?category=${categoryParam}&groupId=${groupId}&type=${type}&page=${page}&size=${size}&keyword=${keyword}&column=${selColumn}&order=desc`)
                }
            }
        }
        else{
            if(selColumn !== ''){
                if(column === selColumn){
                    navigate(`${location.pathname}?page=${page}&size=${size}&keyword=${keyword}&column=${selColumn}&order=${order === 'desc' ? 'asc' : 'desc'}`)
                }else{
                    navigate(`${location.pathname}?page=${page}&size=${size}&keyword=${keyword}&column=${selColumn}&order=desc`)
                }
            }
        }
    }
    useLayoutEffect(()=>{
        if(category === 'individual'){setFilter(filterList)}
        else if(category === 'deleted'){setFilter(filterList2)}
        if(settingName === 'employee'){setFilter(employeeList)}
        if(settingName === 'senders'){setFilter(sendersList)}
        if(groupName === 'group'){setFilter(GroupList)}
        if(groupName === 'addMember')setFilter(addMemberList)
        if(location.pathname === '/template')setFilter(templateList)
        if(location.pathname === '/temporary')setFilter(temporaryList)
        if(category === 'sentMail') setFilter(sentMailList)
        if(category === 'deletedMail') setFilter(deleteSentMailList)
        
    }, [category, settingName, groupName])
    
    return(
        <>
        <thead>
            <tr>
                <th className="check">
                    <div className="checkBox">
                        <input
                            type="checkbox"
                            name="all"
                            id="all"
                            checked={data?.length === selectedMember?.length ? true : false}
                            onChange={(e)=>onAllCheck(e)}
                        />
                        <label htmlFor="all"></label>
                    </div>
                </th>
                {filter.map((filter : any, index : number) => (
                <th key={index}>
                    <span className="sortWrap" onClick={()=>handleSort(filter.column)}>
                        {filter.title}
                        {column === filter.column ? 
                        <span className={`sort ${order}`}></span> : 
                        <span className={filter.column !== '' ? `sort` : ``}></span>
                        }
                    </span>
                </th>
                ))}
            </tr>
        </thead>
        </>
    )
}

const filterList = [
    {idx : 1, title : 'Name', column : 'memberName'},
    {idx : 2, title : 'Email', column : 'memberEmail'},
    {idx : 3, title : 'Mobile', column : 'memberMobile'},
    {idx : 4, title : 'Team', column : 'memberDept'},
    {idx : 5, title : 'Position', column : 'memberPosition'},
    {idx : 6, title : 'Note', column : 'memberNote'},
    {idx : 10, title : '수신동의', column : 'memberActiveStatus'},
]

const filterList2 = [
    {idx : 1, title : 'Name', column : 'memberName'},
    {idx : 2, title : 'Email', column : 'memberEmail'},
    {idx : 3, title : 'Mobile', column : 'memberMobile'},
    {idx : 4, title : 'Team', column : 'memberDept'},
    {idx : 5, title : 'Position', column : 'memberPosition'},
    {idx : 6, title : 'Note', column : 'memberNote'},
]

const employeeList = [
    {idx: 1, title : 'Name', column : 'memberName'},
    {idx: 1, title : 'Email', column : 'memberEmail'},
    {idx: 1, title : 'Mobile', column : 'memberMobile'},
    {idx: 1, title : '관리자 권한', column : ''},
]

const sendersList = [
    {idx: 1, title : 'Name', column : 'memberName'},
    {idx: 1, title : 'Email', column : 'memberEmail'},
    // {idx: 1, title : 'Mobile', column : 'memberMobile'},
    {idx: 1, title : '관리자 권한', column : ''},
]

const addMemberList = [
    {idx : 1, title : 'Name', column : 'memberName'},
    {idx : 2, title : 'Email', column : 'memberEmail'},
    {idx : 3, title : 'Mobile', column : 'memberMobile'},
    {idx : 4, title : 'Team', column : 'memberDept'},
    {idx : 5, title : 'Position', column : 'memberPosition'},
    {idx : 6, title : 'Note', column : 'memberNote'},

]

const GroupList = [
    {idx: 1, title : 'Group', column : 'groupName'},
    {idx: 2, title : 'Members', column : ''},
]

const templateList = [
    {idx : 1, title : 'Thumbnail', column : ''},
    {idx : 1, title : 'Title', column : 'templateName'},
    {idx : 1, title : 'Data', column : ''},
    {idx : 1, title : 'Public', column : ''},
]

const temporaryList = [
    {idx : 1, title : '받는사람', column : ''},
    {idx : 2, title : '제목', column : 'mailSubject'}
]

const sentMailList = [
    {idx : 1, title : '받는사람', column : ''},
    {idx : 2, title : '제목', column : 'mailSubject'},
    {idx : 3, title : '발송일자', column : ''},
    {idx : 4, title : '', column : ''},
    {idx : 5, title : '', column : ''},
]

const deleteSentMailList = [
    {idx : 1, title : '받는사람', column : ''},
    {idx : 2, title : '제목', column : 'mailSubject'},
    {idx : 3, title : '발송일자', column : ''},
    {idx : 4, title : '', column : ''},
    {idx : 5, title : '', column : ''},
]