import React, { useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { useDelTempMemberMutation, useGetCsvFileMutation } from "../../store/slice/membersApiSlice";
import readXlsxFile from 'read-excel-file'
import { SizeBox } from "../item/sizeBox";
import { useAppSelector } from "../../store/storeHooks";
import { ExcelUpload } from "./excelUpload";
export interface Props {getList : Function, selectedMember : any, isOpen : Function, setSelectMember:Function}
export const MemberToolBox:React.FC<Props> = ({getList, selectedMember, isOpen, setSelectMember}) => {
    const {category} = useParams()
    const userInfo = useAppSelector(state=>state.loginUser.userInfo)
    const searchRef = useRef<any>(null)
    const location = useLocation()
    const page : any = new URLSearchParams(location.search).get('page') ;
    const keyword : any = new URLSearchParams(location.search).get('keyword');
    const order : any = new URLSearchParams(location.search).get('order');
    const column : any = new URLSearchParams(location.search).get('column');
    const size = new URLSearchParams(location.search).get('size') || 10;
    const navigate = useNavigate()
    const [delTempMemberApi] = useDelTempMemberMutation()
    const [getCsvFile] = useGetCsvFileMutation()
    // 엑셀 저장 데이터
    const [excelData, setExcelData] = useState<any>([])
    // 회원 삭제 Event
    const handleDelete = async(type : string) => {
        if(selectedMember.length === 0){
            toast.error(errorUndoButton , {style : errorStyle}); return;
        }
        const formData = new FormData()
        formData.append('memberId', selectedMember)
        formData.append('deleteOption', type)
        const result : any = await delTempMemberApi(formData)
        if(result.data.result === true){
            toast.success(type === 'Y' ? successUndoButton : successUndoButton2 , {style : seccessStyle})
            getList(); setSelectMember([])
        }else{alert(result.data.resultMsg)}
    }
    // 회원 검색 Event
    const handleSearch = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter'){
            navigate(`${location.pathname}?page=1&keyword=${searchRef.current.value}&column=memberName&order=asc`)
        }
    }
    // excel Event
    const handleFileChange = async (event : any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const jsonData = await readXlsxFile(file, { sheet: 1 });
                const headers = jsonData[0];
                const mappedData : any = jsonData.slice(1).map(row =>
                    headers.reduce((acc : any, header : any, index) => {
                        acc[header] = row[index];
                        return acc;
                    }, {})
                );
                setExcelData(mappedData);
            } catch (error) {
                console.error("Error : ", error);
            }
        }
    };
    
    return(
        <>
        <div className="toolBox">
            <div className="left">
                <SizeBox/>
                <button className="deleteBtn" onClick={()=>handleDelete('Y')}>
                    <span>삭제</span>
                </button>
                <button className="writingMailBtn" onClick={()=>navigate(`/mails?members=${selectedMember}`)}>
                    <span>메일쓰기</span>
                </button>
                <button className="permanentDeleteBtn" onClick={()=>isOpen(true, 'permanentlyDelete')}>
                    <span>영구삭제</span>
                </button>
                <button className="restoreBtn" onClick={()=>handleDelete('N')}>
                    <span>복원</span>
                </button>

                <div className="txtBox">
                    <span>선택된 회원 </span>
                    <span className="count"> 총 {selectedMember.langth}명</span>
                </div>
            </div>
            <div className="right">
                <div className="searchBox">
                    <div>
                        <span></span>
                        <input type="text" ref={searchRef} onKeyDown={handleSearch} placeholder="Search..."/>
                    </div>
                </div>
                <ExcelUpload refetch={getList}/>
                <button className="memberAddBtn" onClick={()=>isOpen(true, 'newMember')}>
                    <span>회원 추가하기</span>
                </button>
                <button className="groupPlusBtn" onClick={()=>isOpen(true, 'newGroup')}>
                    <span>그룹 추가하기</span>
                </button>
            </div>
        </div>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />
        </>
    )
}

const successUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        회원 정보를 휴지통으로 이동했습니다.
    </span>
    </div>
);

const successUndoButton2 = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        회원 정보를 복원하였습니다.
    </span>
    </div>
);

const seccessStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid #F79E00',
}

const errorUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        회원을 선택하셔야 합니다.
    </span>
    </div>
);

const errorStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid red',
}
