import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../util/cookie/cookies";
import { Dashboard } from "../dashboard";
import { Main } from "../main/main";
import Footer from "./footer/footer";
import Header_Moblie from "./header/mo";
import Header_Pc from "./header/pc";

const LayOut = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')
    
    return(
        <>
        <div className={`wrap`}>
            
            <Dashboard/>
            
        </div>
        </>
    )
}

export default LayOut;