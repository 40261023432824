import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://dotsmail.net/api/controller";

export const templateApiSlice = createApi({
  reducerPath: "templateApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 템플릿 리스트 가져오기
    getTemplateList : builder.mutation({
      query : (param : {managerId : any, page : any, size : number, keyword : string, sortOrder : string, sortColumn : string}) => {
        return{
          url : `/setting/getTemplateList.php?managerId=${param.managerId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}`
        }
      }
    }),
    // 템플릿 저장하기
    setTemplate : builder.mutation({
      query : ( body : any ) => {
        return{
          url : '/setting/setTemplate.php', method : 'post', body
        }
      }
    }),
    // 템플릿 상세보기
    getTemplateDetail : builder.mutation({
      query : (param : {templateId : any}) => {
        return{
          url : `/setting/getTemplateDetail.php?templateId=${param.templateId}`
        }
      }
    }),
    // 탬플릿 수정
    updTemplate : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/updTemplate.php', method : 'post', body
        }
      }
    }),
    // 템플릿 삭제
    delTemplate : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/delTemplate.php', method : 'post', body
        }
      }
    }),
    // 템플릿 상태 변경
    updTemplateStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/updTemplateStatus.php', method : 'post', body
        }
      }
    }),
    // 토스트 에디터 사진 업로드 시 , 서버 저장
    setToastFileUpload : builder.mutation({
      query : (body : any) => {
        return{
          url : '/dotsmail/setToastFileUpload.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
    useGetTemplateListMutation, useSetTemplateMutation, useDelTemplateMutation, useUpdTemplateStatusMutation, useGetTemplateDetailMutation, useUpdTemplateMutation,
    useSetToastFileUploadMutation
} = templateApiSlice;