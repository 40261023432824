import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
export interface Props {categoryList:any}
export const SideBar:React.FC<Props> = ({categoryList}) => {
    const {category, groupName} = useParams()
    const location = useLocation()
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const handleTransPage = (secondUrl : string, url : string) => {
        if(path[2] !== secondUrl){navigate(url)}
    }
    const handleTransPage2 = (url : string) => {
        if(location.pathname !== url) navigate(url)
    }
    const handleMailPage = (url : string) => {
        if(location.pathname !== url) navigate(url)
        else {window.location.reload()}
    }
    return(
        <>
        <aside>
            <ul className="mainMenu">
                <li className={path[1]==='member'?'active member':'member'} onClick={()=>handleTransPage('individual', '/member/individual?page=1&size=10&keyword=&column=memberName&order=asc')}>
                    <span>Member</span>
                </li>
                <li className={path[1]==='groups'?'active group':'group'} onClick={()=>handleTransPage('groups', `/groups/group?category=${categoryList[0].idx}&page=1&size=10&keyword=&column=groupName&order=desc`)}>
                    <span>Group</span>
                </li>
                <li className={path[1]==='mails'?'active writingMail':'writingMail'} onClick={()=>handleMailPage('/mails')}>
                    <span>Writing Mail</span>
                </li>
                <li className={path[1]==='mailbox'?'active sentMailBox' : 'sentMailBox'} onClick={()=>handleTransPage('sentMail', '/mailbox/sentMail?page=1&size=10&keyword=&column=mailSubject&order=desc')}>
                    <span>Sent Mailbox</span>
                </li>
                <li className={path[1]==='temporary'?'active temporary' : 'temporary'} onClick={()=>navigate('/temporary?page=1&size=10&keyword=&column=mailSubject&order=desc')}>
                    <span>Temporary storage</span>
                </li>
                <li className={path[1]==='template'?'mailTemplate active':'mailTemplate'} onClick={()=>navigate('/template?page=1&size=10&keyword=&column=templateName&order=desc')}>
                    <span>Mail Template</span>
                </li>
                <li className={path[1]==='setting'?'active setting' : 'setting'} onClick={()=>handleTransPage('company', '/setting/company')}>
                    <span>Setting</span>
                </li>
            </ul>
        </aside>
        </>
    )
}