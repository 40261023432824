import React from "react";

export const MainFooter = () => {

    return(
        <>
        <div>
            <p>COPYRIGHTⓒ<strong>BMC Co., Ltd</strong> ALL RIGHTS RESERVED.</p>
            <div>
                <button>이용약관</button>
                <button className="yellow">개인정보처리방침</button>
                <div>
                    <button className="">
                        Language
                        <span></span>
                    </button>
                    <div>
                        <button>한국어</button>
                        <button>English</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}