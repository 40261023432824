import React, { useState } from "react";
export interface Props {setData : Function, onClose : Function}
export const ReservationPopup:React.FC<Props> = ({setData, onClose}) => {

    const [date, setDate] = useState<any>({day : '', hour : '00', min : '00'})
    const hoursOptions = Array.from({ length: 24 }, (_, index) => index.toString().padStart(2, '0'));
    const minutesOptions = Array.from({ length: 60 }, (_, index) => index.toString().padStart(2, '0'));

    // 날짜 선택
    const handleDateChange = (e:any) => {
        const {name, type, value} = e.target;
        const selectedDate = new Date(value);
    
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (selectedDate < today) {
        alert("이전 날짜는 선택할 수 없습니다.");
        } else {
        setDate((prev: any) => ({ ...prev, day: value }));
        }
    }

    const handleTimeChange = (e:any) => {
        const {name, value} = e.target
        setDate((prev:any) => ({...prev, [name] : value}))
    }

    const handleSave = () => {
        const fomatDate = `${date.day} ${date.hour}:${date.min}:00`;
        const selectedDateTime = new Date(fomatDate);
        const currentDateTime = new Date();
        if (selectedDateTime <= currentDateTime) {
            alert("이전 시간은 선택할 수 없습니다.");
        } else {
            setData((prev:any) => ({
                ...prev, 
                reservationTime: fomatDate
            }));
            onClose()
        }
    }
    // 확인을 누를 때, time 을 저장한다.
    return(
        <>
        <div className="datePopUp popUp_active active">
            <div>
                <div>
                    <div>
                        <h3>메일 발송예약</h3>
                        <button>
                            <i className="fa-regular fa-xmark"></i>
                        </button>
                    </div>
                    
                    <div>
                        <span>예약시간</span>
                        <div>
                            <div className="input_date_box">
                                <input id="date" type="date" value={date?.day} onChange={handleDateChange}/>
                            </div>
                            <div className="selectBox">
                                <select name="hour" onChange={handleTimeChange}>
                                    {hoursOptions?.map((hour:any) => (
                                        <option key={hour}>{hour}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="selectBox">
                                <select name="min" onChange={handleTimeChange}>
                                    {minutesOptions?.map((min : any) => (
                                        <option key={min}>{min}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="purpleBtn" onClick={handleSave}>예약설정</button>
                </div>
            </div>
        </div>
        </>
    )
}