import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://dotsmail.net/api/controller";

export const userApiSlice = createApi({
  reducerPath: "userApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 로그인
    login : builder.mutation({
      query : (body : {id : string, password : string}) => {
        return{
          url : `/login.php`, method : 'post', body
        }
      }
    }),
    // 로그인한 유저 정보 불러오기
    userInfo : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/userInfo.php', method : 'post', body
        }
      }
    }),
    // 비밀번호 변경
    updPassword : builder.mutation({
      query : (body  :any) => {
        return{
          url : '/setting/updManagerPassword.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useLoginMutation, useUserInfoMutation, useUpdPasswordMutation
} = userApiSlice;