import React, { useEffect, useRef, useState } from "react";
import { useDelCategoryMutation, useUpdCategoryMutation } from "../../../store/slice/groupApiSlice";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {category : any, onAdd:Function, refetch : Function, onClose:Function}
export const CategoryManagementPopup:React.FC<Props> = ({category, onAdd, refetch, onClose}) => {
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const inputRef = useRef<any>(null)
    const inputRef2 = useRef<any>([])
    const [updGroupApi] = useUpdCategoryMutation()
    const [delCategory] = useDelCategoryMutation()
    const [addBtn, setAddBtn] = useState<boolean>(false)
    const [categoryName, setCategoryName] = useState<string>('')
    // 카테고리 추가
    const handleAdd = () => {
        const formData : any = new FormData()
        formData.append('codeId', 'C')
        formData.append('codeCompanyId', userInfo?.companyId)
        formData.append('codeNameKr', categoryName)
        onAdd(formData)
    }
    // 카테고리 명 변경
    const handleEditName = async(idx: number, defaultName:string) => {
        if(defaultName === inputRef2.current[idx].value){
            return;
        }else{
            const formData : any = new FormData()
            formData.append('ID', idx)
            formData.append('categoryName', inputRef2.current[idx].value)
            const result : any = await updGroupApi(formData)
            if(result.data.result === true){refetch()}
            else{alert(result.data.resultMsg)}
        }
    }
    // 카테고리 삭제
    const handleDelCategory = async(idx:number) => {
        const formData : any = new FormData()
        formData.append('managerId' , userInfo?.apiId)
        formData.append('categoryId', idx)
        const result : any = await delCategory(formData)
        if(result.data.result === true){refetch()}
        else{alert(result.data.resultMsg)}
    }
    useEffect(()=>{if(inputRef.current){inputRef.current.focus()}},[addBtn])
    return(
        <>
        <div className="addPopUp popUp_active active">
            <div className="category">
                <div>
                    <div>
                        <button><i className="fa-regular fa-xmark" onClick={()=>onClose()}></i></button>
                        <h3>카테고리 관리</h3>
                    </div>
                    <div>
                        <div>
                            <span>카테고리</span>
                            <div className="inputWrap">
                                {category?.map((list:any)=>(
                                <>
                                <div>
                                    <input ref={(e)=>inputRef2.current[list?.idx]=e} type="text" name="" id="test" defaultValue={list?.categoryName}/>
                                    <button onClick={()=>handleDelCategory(list?.idx)}>삭제</button>
                                    <label htmlFor="test" onClick={()=>handleEditName(list?.idx, list?.categoryName)}>이름변경</label>
                                </div>
                                </>
                                ))}
                            </div>
                            <div className="addBox">
                                <button className={addBtn?'active':''} onClick={()=>setAddBtn(true)}>카테고리 추가하기</button>
                                <input ref={inputRef} type="text" onChange={(e)=>setCategoryName(e.target.value)} name="" id="" placeholder="Enter a Category"/>
                            </div>
                        </div>
                        {/* <!-- 선택 그룹에 추가 --> */}
                    </div>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="purpleBtn" onClick={handleAdd}>저장</button>
                </div>
            </div>
        </div>
        </>
    )
}