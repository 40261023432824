import React, { useEffect, useState } from "react";
import { useGetEmailSentTemplateMutation } from "../../../store/slice/sentmailApiSlice";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {
    info : any
    onClose : Function
    template : any
}
export const TemplatePreviewPopup:React.FC<Props> = ({info, onClose, template}) => {
    console.log(template)
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const [data, setData] = useState<any>(null)
    const [getEmailSentTemplateApi] = useGetEmailSentTemplateMutation()
    const getDetail = async() => {
        const res : any = await getEmailSentTemplateApi({mailId : info?.mailId, managerUuid : userInfo?.userId})
        if(res.data.result === true) {setData(res.data)}
        else{alert(res.data.resultMsg)}
    }
    const [isOpen, setOpen] = useState<boolean>(false)
    const handleOpen=()=>{
        setOpen(!isOpen)
    }
    useEffect(()=>{
        if(info?.mailId) getDetail()
    }, [info?.mailId])
    useEffect(()=>{
        const handleEscPress = (e:KeyboardEvent) => {
            if(e.key === 'Escape') onClose()
        }
        document.addEventListener('keydown', handleEscPress)
        return () => {
            document.removeEventListener('keydown', handleEscPress)
        }
    }, [onClose])
    return(
        <>
        <div className="previewPopUp sentMail popUp_active active">
            <div>
                <button onClick={()=>onClose()}><i className="fa-regular fa-xmark"></i></button>
                <h3>{data?.mailHistory[0]?.mailSubject}</h3>
                {/* <div className="top">
                    <span>받는 사람</span>
                    <span>회원 전체</span>
                </div> */}
                {/* <!-- 전체회원에게 전송할 때 --> */}


                {/* <!-- 클릭 시 active 클래스 추가하면 ul이 활성화 됨 --> */}
                <div className={isOpen ? 'top select_member active' : 'top select_member'}>
                    <span onClick={handleOpen}>받는 사람</span>
                    <span onClick={handleOpen}>{data?.receiverSummary}</span>

                    <ul>
                        {data?.receiverList?.map((receiver:any) => (
                        <li>
                            <span>{receiver?.receivers}</span>
                            <button><i className="fa-regular fa-xmark"></i></button>
                        </li>
                        ))}
                    </ul>
                </div>
                {/* <!-- 선택회원, 그룹선택 전송할 때 --> */}

                {/* <div className="imgBox">
                    <img src="/assets/images/template_preview.png" alt="미리보기"/>
                </div> */}
                {/* 탬플릿 아이디가 0 일때 */}
                {template?.templateId === '0' &&
                <div style={{width : '650px', margin : '50px auto', fontFamily : 'pretendard'}}>
                    <table style={{marginBottom : '15px', border : '1px solid #DDDDDD', borderRadius : '5px', width : '100%', fontFamily : 'pretendard'}} cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th style={{backgroundColor : '#fff', fontFamily : 'pretendard', textAlign : 'left', padding : '25px 30px 35px 30px', boxSizing : 'border-box'}}>
                                    <a href="https://www.dklok.com/" target="_blank" rel="noreferrer noopener">
                                        <img style={{objectFit : 'contain', height : '20px'}} src="https://dklok.com/assets/images/dkLok_logo.png" loading="lazy" alt="logo"/>
                                    </a>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="timenow" style={{fontFamily : 'pretendard', textAlign : 'right', padding : '0 15px 10px 0', boxSizing : 'border-box', fontSize : '12px', fontWeight : '300', color : '#999999', borderBottom : '1px solid #DDDDDD'}}>
                                    <span className="timenow">{data?.mailHistory[0]?.mailSendDate}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding : '0 40px', boxSizing : 'border-box'}}>
                                    <h2 style={{margin : '30px 0', fontSize : '28px', color : '#0E56A7'}}>{data?.mailHistory[0]?.mailSubject}</h2>
                                    <div style={{fontSize : '14px', fontFamily : 'pretendard'}} dangerouslySetInnerHTML={{__html : data?.mailHistory[0]?.mailContents}}>

                                    </div>
                                    <a style={{marginTop : '30px', fontFamily : 'pretendard', display : 'block', backgroundColor : '#444444', borderRadius : '5px', color : '#fff', fontSize : '16px', fontWeight : '400', textDecoration : 'none', textAlign : 'center', padding : '15px 0'}} target="_blank" rel="noreferrer noopener">
                                        개발 진행 중
                                    </a>
                                </td>
                            </tr>
                        <tr>
                        <td style={{paddingTop : '30px'}}></td>
                        </tr>
                        <tr>
                            <td style={{padding : '30px 40px 0 40px', boxSizing : 'border-box', backgroundColor : '#F2F2F2'}}>
                                <p style={{fontFamily : 'pretendard', display : 'flex', margin : '0', marginBottom : '10px', fontSize : '12px', fontWeight : '300', color : '#777777'}}><span>대표 : 노은식</span><span style={{fontSize : '12px', fontWeight : '300', color : '#CCCCCC', padding : '0 15px'}}> | </span><span>사업자등록번호 : 606-81-62536</span></p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding : '0 40px', boxSizing : 'border-box', backgroundColor : '#F2F2F2'}}>
                                <p style={{fontFamily : 'pretendard', display : 'flex', margin : '0', marginBottom : '10px', fontSize : '12px', fontWeight : '300', color : '#777777'}}>경상남도 김해시 주촌면 골든루트로 129번길 7</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding : '0 40px', boxSizing : 'border-box', backgroundColor : '#F2F2F2'}}>
                                <p style={{fontFamily : 'pretendard', display : 'flex', margin : '0', marginBottom : '10px', fontSize : '12px', fontWeight : '300', color : '#777777'}}>대표전화 : 055-338-0114<span style={{fontSize : '12px', fontWeight : '300', color : '#CCCCCC', padding : '0 15px'}}> | </span>dklok@dklok.com</p>
                            </td>
                        </tr>
                        <tr>
                            <td style={{padding : '10px 40px 0 40px', boxSizing : 'border-box', backgroundColor : '#F2F2F2', paddingBottom : '25px'}}>
                                <p style={{fontFamily : 'pretendard', display : 'flex', margin : '0', marginBottom : '10px', fontSize : '12px', fontWeight : '300', color : '#777777'}}>
                                    <span style={{fontSize : '12px', fontWeight : '300', color : '#AAAAAA'}}>메일 수신을 원치 않으시면 <a href="" style={{fontSize : '12px', fontWeight : '300', color : '#AAAAAA'}}>수신거부</a>를 눌러주세요.</span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                    </table>
                <p style={{fontFamily : 'pretendard', fontSize  :'12px', fontWeight : '300', color : '#AAAAAA', textAlign : 'center'}}>Copyright © DK-LOK All rights reserved.</p>
                </div>
                }

                {/* 탬플릿 아이디가 0이 아닐 때 */}
                {template?.templateId !== '0' &&
                <iframe
                    srcDoc={`${template?.templateContents}`}
                    style={{width : '100%', height : '600px'}}
                ></iframe>
                }


                <div className="dateBox">
                    <span>2023-03-13  15:02</span>
                    {/* <span>128명 중 32명 읽음</span> */}
                </div>
            </div>
        </div>
        </>
    )
}
