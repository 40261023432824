import React, { useEffect, useRef, useState } from "react";
import { PopupData } from "../../../pages/dashboard/mailBox";
import { DataType } from "./newMemberPopup";
import { useGetMemberDetailMutation, useUpdMemberActiveStatusMutation, useUpdMemberMutation } from "../../../store/slice/membersApiSlice";
import { ToggleBox } from "../../item/toggleBox";
export interface Props {getList:Function, info : any, onClose : Function, category : any}
export const EditMemberPopup:React.FC<Props> = ({getList, info, onClose, category}) => {
    const pointerRef = useRef<any>(null)
    const [getMemberDetailApi] = useGetMemberDetailMutation()
    const [updMemberApi] = useUpdMemberMutation()
    const [updMemberActiveStatusApi] = useUpdMemberActiveStatusMutation()
    const [type, setType] = useState<string>('')
    const [data, setData] = useState<any>({memberName : '', memberCompany : '', memberEmail : '', memberMobile : '', memberPhone : '', memberDept  : '', memberPosition : '', groups : [],
    address : '', memberNote : '', agree : ''})
    const getDetail = async() => {
        const formData:any = new FormData()
        formData.append('memberId', info?.memberInfo?.memberId)
        const result : any = await getMemberDetailApi(formData)
        if(result.data.result === true){
            const detail = result.data.list[0]
            setData((prev:any)=>({...prev, memberName : detail?.memberName, memberCompany : detail?.memberCompany, memberEmail : detail?.memberEmail, memberMobile : detail?.memberMobile,
            memberPhone : detail?.memberPhone, memberDept : detail?.memberDept, memberPosition : detail?.memberPosition, address : detail?.memberAddress, groups : detail?.memberGroup,
            memberNote : detail?.memberNote, agree : detail?.memberActiveStatus}))
        }else{alert(result.data.resultMsg)}
    }
    const handleChange = (e:any) => {
        const {type, value, name} = e.target
        setData((prev:any)=>({...prev, [name] : value}))
    }
    const handleChangeActiveStatus = async() => {
        const formData : any = new FormData()
        formData.append('memberId' , info?.memberInfo?.memberId)
        formData.append('memberActiveStatus', data?.agree === 'Y' ? 'N' : 'Y')
        const result : any = await updMemberActiveStatusApi(formData)
        if(result.data.result === true){getDetail()}else{alert(result.data.resultMsg)}
    }
    const handleSave = async(type : string) => {
        const formData : any = new FormData()
        formData.append('memberId', info?.memberInfo?.memberId)
        formData.append(type, data?.[type])
        const result : any = await updMemberApi(formData)
        if(result.data.result ===true){setType(''); getDetail()}
    }
    const handleClose = () => {
        onClose(); getList()
    }
    useEffect(()=>{getDetail()}, [info])
    useEffect(()=>{
        if(pointerRef.current){pointerRef.current.focus()}
    }, [type])
    return(
        <>
        <div className={`userInfo_popUp popUp_active active`}>
            <button className="closeBtn" onClick={handleClose}>
                <i className="fa-light fa-xmark"></i>
            </button>
            <div className="btnBox">
                <div className="mailSendBox">
                    <button className="mailSendBtn">
                        <span></span>
                    </button>
                    <span>메일전송</span>
                </div>
    
                <div className="toggleSwitchBox">
                    <label htmlFor="memberStatUs">수신동의</label>
                    <span className="toggleSwitch">
                        <input type="checkbox" id={`toggle_2_${info?.memberInfo?.memberId}`} className="toggleIpt" onChange={handleChangeActiveStatus} checked={data?.agree === 'Y' ? true : false} hidden/> 
                        <label htmlFor={`toggle_2_${info?.memberInfo?.memberId}`} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </div>
            </div>
            <div className="userInfoBox">
                <div>
                    <div className="userNameBox">
                        <div>
                            <span>{data?.memberName}</span>
                            <span>{data?.memberCompany}</span>
                        </div>
                    </div>

                    <ul>
                        <li className={type === 'memberName' ? 'active' : ''}>
                            <span>이름</span>
                            {type === 'memberName' ? 
                            <input type="text" ref={pointerRef} name="memberName" value={data?.memberName} onChange={handleChange} placeholder="이름을 입력해 주세요."/> : 
                            <span>{data?.memberName}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberName')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberName')}>저장</button>
                            </div>
                        </li>
                        <li className={type === 'memberCompany' ? 'active' : ''}>
                            <span>회사명</span>
                            {type === 'memberCompany' ? 
                            <input type="text" ref={pointerRef} name="memberCompany" value={data?.memberCompany} onChange={handleChange} placeholder="회사명을 입력해 주세요."/> : 
                            <span>{data?.memberCompany}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberCompany')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberCompany')}>저장</button>
                            </div>
                        </li>
                        <li className={type === 'memberEmail' ? 'active' : ''}>
                            <span>이메일</span>
                            {type === 'memberEmail' ? 
                            <input type="text" ref={pointerRef} name="memberEmail" value={data?.memberEmail} onChange={handleChange} placeholder="이메일을 입력해 주세요."/> : 
                            <span>{data?.memberEmail}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberEmail')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberEmail')}>저장</button>
                            </div>
                        </li>
                        <li className={type === 'memberMobile' ? 'active' : ''}>
                            <span>연락처</span>
                            {type === 'memberMobile' ? 
                            <input type="text" ref={pointerRef} name="memberMobile" value={data?.memberMobile} onChange={handleChange} placeholder="하이픈(-)을 제외한 번호를 입력해 주세요."/>:
                            <span>{data?.memberMobile}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberMobile')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberMobile')}>저장</button>
                            </div>
                        </li>
                        <li className={type === 'memberPhone' ? 'active' : ''}>
                            <span>핸드폰 번호</span>
                            {type === 'memberPhone' ? 
                            <input type="text" ref={pointerRef} name="memberPhone" value={data?.memberPhone} onChange={handleChange} placeholder="하이픈(-)을 제외한 번호를 입력해 주세요."/>:
                            <span>{data?.memberPhone}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberPhone')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberPhone')}>저장</button>
                            </div>
                        </li>
                        <li className={type === 'memberDept' ? 'active' : ''}> 
                            <span>부서</span>
                            {type === 'memberDept' ? 
                            <input type="text" ref={pointerRef} name="memberDept" value={data?.memberDept} onChange={handleChange} placeholder="부서명을 입력해 주세요."/>:
                            <span>{data?.memberDept}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberDept')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberDept')}>저장</button>
                            </div>
                        </li>
                        <li className={type === 'memberPosition' ? 'active' : ''}>
                            <span>직책</span>
                            {type === 'memberPosition' ? 
                            <input type="text" ref={pointerRef} name="memberPosition" value={data?.memberPosition } onChange={handleChange} placeholder="직책을 입력해 주세요."/>:
                            <span>{data?.memberPosition}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberPosition')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberPosition')}>저장</button>
                            </div>
                        </li>
                        {/* {data?.groups?.map((group : any) => (
                            <li className={type === group?.categoryName ? 'active' : ''} key={group?.idx}>
                                <span>{group?.categoryName}</span>
                                <span>{group?.groupName}</span>
                                <div>
                                    <button className="editBtn" onClick={()=>setType(group?.categoryName)}>수정</button>
                                    <button className="saveBtn">저장</button>
                                </div>
                            </li>
                        ))} */}
                        <li className={type === 'address' ? 'active' : ''}>
                            <span>Address</span>
                            {data?.address}
                            <div>
                                <button className="editBtn" onClick={()=>setType('address')}>수정</button>
                                <button className="saveBtn">저장</button>
                            </div>
                        </li>
                        <li className={type === 'memberNote' ? 'active' : ''}>
                            <span>Note</span>
                            {type === 'memberNote' ? 
                            <input type="text" ref={pointerRef} name="memberNote" value={data?.memberNote} onChange={handleChange} placeholder="메모를 기입해 주세요."/>:
                            <span>{data?.memberNote}</span>
                            }
                            <div>
                                <button className="editBtn" onClick={()=>setType('memberNote')}>수정</button>
                                <button className="saveBtn" onClick={()=>handleSave('memberNote')}>저장</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}