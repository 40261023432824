import React from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack'
import { useLocation } from "react-router-dom";
export interface Props {page:any; totalCount:number; size : number, onPage : Function}
export const Paginate:React.FC<Props> = ({page,totalCount,size, onPage}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const selPage = (e:React.ChangeEvent<unknown>, pageNumber : number) => {
       onPage(pageNumber)
    }
    
    return(
        <>
        <div className="pagerBox">
            
            {path[1] === 'dotsAdmin' ? 
            <p>Showing 1 to {size} of 98 entries</p> : ''
            }

            <Stack spacing={2}>
                <Pagination page={parseInt(page)} count={Math.ceil(totalCount/size)} shape="rounded" onChange={selPage}/>
            </Stack>

        </div>
        </>
    )
}