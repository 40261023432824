import React, { useEffect, useState } from "react";
import { useGetCompanyInfoMutation, useUpdCompanyMutation } from "../../../../store/slice/settingApiSlice";
import { useAppSelector } from "../../../../store/storeHooks";
export interface Props {refetch : Function, detail:any}
export const Company:React.FC<Props> = ({refetch, detail}) => {
    const userInfo = useAppSelector(state => state.loginUser.userInfo)
    const [updCompany] = useUpdCompanyMutation()
    const [data, setData] = useState<any>({
        companyNameKr : '', companyNameEn : '', ceoNameKr : '', ceoNameEn : '', companyAddressKr : '', companyAddressEn : '', companyPhone : '',
        companyLicense : '', colorLogoFile : '', colorLogoFilename : '', whiteLogoFile : '', whiteLogoFilename : ''
    })
    const handleChange = (e:any) => {
        const {type, name, id, value} = e.target;
        if(type === 'file'){
            const reader = new FileReader();
            if(e.target.files[0]){reader.readAsDataURL(e.target.files[0])}
            reader.onload=()=>{
                setData((prev:any)=>({...prev, [name] : e.target.files[0], [id] : e.target.files[0].name}));
                // setData((prev:any)=>({...prev, [id] : e.target.files[0].name}))
            }
        }else{
            setData((prev:any)=>({...prev, [name] : value}))
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        formData.append('ID', userInfo?.companyId)
        formData.append('companyNameKr', data?.companyNameKr)
        formData.append('companyNameEn', data?.companyNameEn)
        if(data?.colorLogoFile){formData.append('colorLogoFile', data?.colorLogoFile)}
        if(data?.whiteLogoFile){formData.append('whiteLogoFile', data?.whiteLogoFile)}
        formData.append('companyCeoNameKr', data?.ceoNameKr)
        formData.append('companyCeoNameEn', data?.ceoNameEn)
        formData.append('companyLicense', data?.companyLicense)
        formData.append('companyAddress1Kr', data?.companyAddressKr)
        formData.append('companyAddress1En', data?.companyAddressEn)
        // formData.append('companyAddress2Kr', data?.companyAddressKr)
        // formData.append('companyAddress2En', data?.companyAddressEn)
        formData.append('companyPhone', data?.companyPhone)
        const result : any = await updCompany(formData)
        if(result.data.result === true){refetch(); alert('수정이 완료되었습니다.');}
        else{alert(result.data.resultMsg)}
    }
    useEffect(()=>{
        if(detail){setData(detail)}
    }, [detail])
    return(
        <>
        <div className="company">
            <div className="tableWrap">
                <table className="tableType_c">
                    <tbody>
                        <tr>
                            <th>회사명</th>
                            <td>
                                <div className="lang">
                                    <div>
                                        <input type="text" value={data?.companyNameKr} name="companyNameKr" onChange={handleChange} id="" placeholder="국문 회사명을 입력해 주세요."/>
                                    </div>
                                    <div>
                                        <input type="text" value={data?.companyNameEn} name="companyNameEn" onChange={handleChange} id="" placeholder="영문 회사명을 입력해 주세요."/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>대표자 명</th>
                            <td>
                                <div className="lang">
                                    <div>
                                        <input type="text" value={data?.ceoNameKr} name="ceoNameKr" onChange={handleChange} id="" placeholder="국문 이름을 입력해 주세요."/>
                                    </div>
                                    <div>
                                        <input type="text" value={data?.ceoNameEn} name="ceoNameEn" onChange={handleChange} id="" placeholder="영문 이름을 입력해 주세요."/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        {/* <tr>
                            <th>회사주소(본사)</th>
                            <td>
                                <div className="lang">
                                    <div>
                                        <input type="text" name="" id="" placeholder="국문 주소를 입력해 주세요."/>
                                    </div>
                                    <div>
                                        <input type="text" name="" id="" placeholder="영문 주소를 입력해 주세요."/>
                                    </div>
                                </div>
                            </td>
                        </tr> */}
                        <tr>
                            <th>회사주소</th>
                            <td>
                                <div className="lang">
                                    <div>
                                        <input type="text" value={data?.companyAddressKr} name="companyAddressKr" onChange={handleChange} id="" placeholder="국문 주소를 입력해 주세요."/>
                                    </div>
                                    <div>
                                        <input type="text" value={data?.companyAddressEn} name="companyAddressEn" onChange={handleChange} id="" placeholder="영문 주소를 입력해 주세요."/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>연락처</th>
                            <td>
                                <div>
                                    <div>
                                        <input type="text" value={data?.companyPhone} name="companyPhone" onChange={handleChange} id="" placeholder="하이픈(-)을 제외한 번호를 입력해 주세요."/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>사업자등록번호</th>
                            <td>
                                <div>
                                    <div>
                                        <input type="text" value={data?.companyLicense} name="companyLicense" onChange={handleChange} id="" placeholder="하이픈(-)을 제외한 번호를 입력해 주세요."/>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>로고(컬러)</th>
                            <td>
                                <div className="attachments">
                                    <div>
                                        <input type="file" name="colorLogoFile" onChange={handleChange} id="colorLogoFilename"/>
                                        <label htmlFor="colorLogoFilename">Select a file</label>
                                    </div>
                                    {data?.colorLogoFilename === null || data?.colorLogoFilename === '' ?
                                    <span>* No file selected</span> :
                                    <>
                                    <span>{data?.colorLogoFilename}</span>
                                    {/* <button>
                                        <i className="fa-regular fa-xmark"></i>
                                    </button> */}
                                    </>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>로고(화이트)</th>
                            <td>
                                <div className="attachments">
                                    <div>
                                        <input type="file" name="whiteLogoFile" onChange={handleChange} id="whiteLogoFilename"/>
                                        <label htmlFor="whiteLogoFilename">Select a file</label>
                                    </div>
                                    {data?.whiteLogoFilename === null || data?.whiteLogoFilename === '' ?
                                    <span>* No file selected</span> :
                                    <>
                                    <span>{data?.whiteLogoFilename}</span>
                                    {/* <button>
                                        <i className="fa-regular fa-xmark"></i>
                                    </button> */}
                                    </>
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="btnBox">
            <button className="purpleBorderBtn">취소</button>
            <button className="purpleBtn" onClick={handleSave}>저장</button>
        </div>
        </>
    )
}