import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://dotsmail.net/api/controller";

export const settingApiSlice = createApi({
  reducerPath: "settingApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 관리자 정보 불러오기
    getManager : builder.mutation({
      query : (param : {managerId : any, page: any, size : number, keyword : string, column : string, order : string}) => {
        return{
          url : `/manager/getManagerList.php?managerId=${param.managerId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.column}&sortOrder=${param.order}`
        }
      }
    }),
    // 관리자 추가하기
    setManager : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/setManager.php', method : 'post', body
        }
      }
    }),
    // 기업 정보 불러오기
    getCompanyInfo : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/getCompanyInfo.php', method : 'post', body
        }
      }
    }),
    // 기업 정보 수정
    updCompany : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/updCompany.php', method : 'post', body
        }
      }
    }),
    // 전송자 리스트 가져오기
    getSenderList : builder.mutation({
      query : (param : {managerId : any, page : any, size : number, keyword : string, column : string, order : string}) => {
        return{
          url : `/setting/getSendorList.php?managerId=${param.managerId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.column}&sortOrder=${param.order}`
        }
      }
    }),
    // 전송자 등록
    setSendor : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/setSendor.php', method : 'post', body
        }
      }
    }),
    // 전송자 삭제
    delSender : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setting/delSendor.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetManagerMutation, useGetCompanyInfoMutation, useUpdCompanyMutation, useSetManagerMutation, useGetSenderListMutation, useSetSendorMutation, useDelSenderMutation
} = settingApiSlice;