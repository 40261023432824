import React from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
interface Props {reservationTime : string}
export const MailReservation:React.FC<Props> = ({reservationTime}) => {
    const navigate = useNavigate()
    return(
        <>
        <div className="reservedCompleted active">
            <div>
                <p>
                    요청하신 메일 발송 예약이 완료되었습니다.<br/>
                    <strong>예약된 메일은 보낸메일함에서 취소가 가능합니다.</strong>
                </p>
                <span>
                    <Moment format="YYYY.MM.DD HH시 mm분">{reservationTime}</Moment> 발송 예정
                </span>
                <div>
                    <button className="purpleBorderBtn" onClick={()=>navigate('/mailbox/sentMail?page=1&size=10&keyword=&column=mailSubject&order=desc')}>보낸 메일함</button>
                    <button className="purpleBtn" onClick={()=>window.location.reload()}>메일쓰기</button>
                </div>
            </div>
        </div>
        </>
    )
}