import { combineReducers, configureStore  } from '@reduxjs/toolkit'
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { membersApiSlice } from './slice/membersApiSlice';
import { userApiSlice } from './slice/userApiSlice';
import loginUserReducer from './slice/userInfoSlice';
import { groupApiSlice } from './slice/groupApiSlice';
import { settingApiSlice } from './slice/settingApiSlice';
import { mailApiSlice } from './slice/mailApiSlice';
import { templateApiSlice } from './slice/templateApiSlice';
import { temporaryApiSlice } from './slice/temporaryApiSlice';
import { sentmailApiSlice } from './slice/sentmailApiSlice';

const reducer = combineReducers({})

export const store = configureStore({
  reducer:  {
    loginUser : loginUserReducer,
    [userApiSlice.reducerPath] : userApiSlice.reducer,
    [membersApiSlice.reducerPath] : membersApiSlice.reducer,
    [groupApiSlice.reducerPath] : groupApiSlice.reducer,
    [settingApiSlice.reducerPath] : settingApiSlice.reducer,
    [mailApiSlice.reducerPath] : mailApiSlice.reducer,
    [templateApiSlice.reducerPath] : templateApiSlice.reducer,
    [temporaryApiSlice.reducerPath] : temporaryApiSlice.reducer,
    [sentmailApiSlice.reducerPath] : sentmailApiSlice.reducer
  },
  
  middleware: getMiddleware => getMiddleware().concat(userApiSlice.middleware, membersApiSlice.middleware, groupApiSlice.middleware, settingApiSlice.middleware,
    mailApiSlice.middleware, templateApiSlice.middleware, temporaryApiSlice.middleware, sentmailApiSlice.middleware),
  devTools: process.env.NODE_ENV === 'development'
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
