import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://dotsmail.net/api/controller";

export const sentmailApiSlice = createApi({
  reducerPath: "sentmailApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 보낸 메일함 불러오기
    getSentMailList : builder.mutation({
      query : (param : {managerUuid : any, page: any, size : number, keyword : string, column : string, order : string}) => {
        return{
          url : `/dotsmail/getSentEmailList.php?managerUuid=${param.managerUuid}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.column}&sortOrder=${param.order}`
        }
      }
    }),
    // 오픈수신자 리스트 가져오기
    getOpenUserList : builder.mutation({
      query : (param : {managerUuid : any, mailId : any}) => {
        return{
          url : `/dotsmail/reports/getEmailOpenMemberList.php?managerId=${param.managerUuid}&mailId=${param.mailId}`
        }
      }
    }),
    // 클릭수신자 리스트 가져오기
    getClickUserList : builder.mutation({
      query : (param : {managerUuid : any, mailId : any}) => {
        return{
          url : `/dotsmail/reports/getEmailClickMemberList.php?managerId=${param.managerUuid}&mailId=${param.mailId}`
        }
      }
    }),
    // 보낸 메일 미리보기 (템플릿)
    getEmailSentTemplate : builder.mutation({
      query : (param : {mailId : any , managerUuid : any}) => {
        return {
          url : `/dotsmail/reports/getEmailSentResult.php?mailId=${param.mailId}&managerUuid=${param.managerUuid}`
        }
      }
    })
  }),
});

export const {
  useGetSentMailListMutation, useGetOpenUserListMutation, useGetClickUserListMutation, useGetEmailSentTemplateMutation
} = sentmailApiSlice;