import React, { useEffect } from 'react';
import './App.css';
import './locale/i18n'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Test } from './pages/test/test';
import LayOut from './pages/layout';
import { getCookie, setCookie } from './util/cookie/cookies';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Main } from './pages/main/main';
import { useUserInfoMutation } from './store/slice/userApiSlice';
import { useAppDispatch } from './store/storeHooks';
import { setuserInfo } from './store/slice/userInfoSlice';

function App() {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch() 
  const userId = sessionStorage.getItem('keys')
  const [userInfo] = useUserInfoMutation()
  const getUserInfo = async() => {
    try{
      const formData : any = new FormData()
      formData.append('managerId', userId)
      const result : any = await userInfo(formData)
      if(result.data.result === true){
        dispatch(setuserInfo({userId : result.data.list[0].managerUuid,
          account : result.data.list[0].managerEmail,
          name : result.data.list[0].managerName,
          isAdmin : result.data.list[0].managerAdmin, 
          apiId : result.data.list[0].managerId, 
          companyId : result.data.list[0].managerCompanyId}))
      }else{
        navigate('/login')
      }
    }
    catch{
      navigate('/login')
    }
  }
  useEffect(()=>{
      getUserInfo()
  }, [dispatch])
  useEffect(() => {window.scrollTo({ top: 0, behavior: 'auto' })}, [location]);
  return (
    <>
    <Routes>
      <Route path='/' element={<Main/>}/>
      <Route path='/login' element={<Main/>}/>
      <Route path='/signup' element={<Main/>}/>
      <Route path='/findId' element={<Main/>}/>
      <Route path='/findPw' element={<Main/>}/>
      
      <Route path='/member/:category' element={<LayOut/>}/>
      <Route path='/groups/:groupName' element={<LayOut/>}/>
      <Route path='/mails' element={<LayOut/>}/>
      <Route path='/mailbox/:category' element={<LayOut/>}/>
      <Route path='/temporary' element={<LayOut/>}/>
      <Route path='/setting/:settingName' element={<LayOut/>}/>
      <Route path='/template' element={<LayOut/>}/>
      <Route path='/template/:templateId' element={<LayOut/>}/>
      <Route path='/test' element={<Test/>}/>
    </Routes>
    </>
  );
}

export default App;
