import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetTemplateListMutation, useUpdTemplateStatusMutation } from "../../../store/slice/templateApiSlice";
import { Paginate } from "../../../components/paginate/paginate";
import { ToggleBox } from "../../../components/item/toggleBox";
import { TemplateToolBox } from "../../../components/template/templateToolBox";
import { MemberListFilter } from "../member/category/list-filter";
import { useAppSelector } from "../../../store/storeHooks";

export const TemplateList = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const userId = useAppSelector((state)=>state.loginUser.userInfo.apiId)
    const page : any = new URLSearchParams(location.search).get('page') || 1;
    const size : any = new URLSearchParams(location.search).get('size') || 10;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const column : any = new URLSearchParams(location.search).get('column') || 'templateName';
    const order : any = new URLSearchParams(location.search).get('order') || 'desc';
    const [getTemplate] = useGetTemplateListMutation()
    const [updTemplateStatus] = useUpdTemplateStatusMutation()
    const [data, setData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [selectTemplate, setSelectTemplate] = useState<any>([])
    const [param, setParam] = useState<any>({managerId : userId, page : page, size : size, keyword : keyword, sortColumn : column, sortOrder : order})
    const getList = async() => {
        const result : any = await getTemplate(param)
        if(result.data.result === true){setData(result.data.list); setTotalCount(result.data.total_cnt)}
    }
    const handleChangeStatus = async(id:string, status : string) => {
        const formData : any = new FormData()
        formData.append('templateId', id)
        formData.append('templateStatus', status === 'Y'?'N':'Y')
        const result : any = await updTemplateStatus(formData)
        if(result.data.result === true){
            getList()
        }else{alert(result.data.resultMsg)}
    }
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        if(name === 'all'){
            if(data){
                if(checked){setSelectTemplate(data.map((template:any)=>template.templateId.toString()))}
                else{setSelectTemplate([])}
            }
        }else{
            if(checked){setSelectTemplate((prev:any)=>[...prev, e.target.id])}
            else {setSelectTemplate((prev:any)=>prev.filter((id:any)=>id !== e.target.id))}
        }
    }
    const handlePage = () => {
        
    }
    useEffect(()=>{
        setParam((prev:any)=>({...prev,managerId : userId,page:page,size:size,keyword:keyword,sortColumn:column,sortOrder:order}))
    }, [userId, page, size, keyword, column, order])
    useEffect(()=>{
        getList()
    }, [param.managerId, param.page, param.size, param.keyword, param.sortColumn, param.sortOrder])
    return(
        <>
        <div>
            <h2>Template</h2>
            <div className="tabBtnBox">
                <button className="active">Template</button>
            </div>

            <TemplateToolBox
                refetch={getList}
                selectedTemplate={selectTemplate}
                setSelectTemplate={setSelectTemplate}
            />
            {/* <!-- toolBox Fin --> */}

            <div className="flexWrap">

                <div className="template">
                    <div className="tableWrap">
                        <table className="tableType_a">
                            <MemberListFilter
                                data={data}
                                selectedMember={selectTemplate}
                                onAllCheck={handleChange}
                            />
                            <tbody>
                                {data?.map((list:any) => (
                                <tr key={list?.templateId}>
                                    <td className="check">
                                        <div className="checkBox">
                                            <input type="checkbox" name="" id={list?.templateId} checked={selectTemplate.includes(list?.templateId.toString())} onChange={handleChange}/>
                                            <label htmlFor={list?.templateId}></label>
                                        </div>
                                    </td>
                                    <td onClick={()=>navigate(`/template/${list?.templateId}`)}>
                                        <div className="tamplate_thumb">
                                            <img src={list?.templateImage} alt="template thumb"/>
                                        </div>
                                    </td>
                                    <td onClick={()=>navigate(`/template/${list?.templateId}`)}>
                                        <span>{list?.templateName}</span>
                                    </td>
                                    <td onClick={()=>navigate(`/template/${list?.templateId}`)}>
                                        <span>{list?.templateMdate}</span>
                                    </td>
                                    <td>
                                        <ToggleBox
                                            name="template"
                                            id={list?.templateId}
                                            status={list?.templateActiveStatus}
                                            onChange={handleChangeStatus}
                                        />
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <!-- template Fin --> */}



                <div className="pagerBox">
                    <Paginate page={page} size={size} totalCount={totalCount} onPage={handlePage}/>
                </div>

            </div>
            {/* <!-- pagerBox 를 하단에 붙이기 위한 wrap --> */}
        </div>
        </>
    )
}