import React from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
export interface Props {data : any, isSentMail : boolean, onOpen : Function, selectMail : any, onChange : Function, setTempalte : Function}
export const MailBoxList:React.FC<Props> = ({data, isSentMail, onOpen, selectMail, onChange, setTempalte}) => {
    const {category} = useParams()
    return(
        <>
        <tbody>
            {data?.map((list:any, index:number) => {
                return(
                    <tr key={index}>
                        <td className="check">
                            <div className="checkBox">
                                <input type="checkbox" name="" id={list?.mailId} checked={selectMail.includes(list?.mailId.toString())} onChange={(e)=>onChange(e)}/>
                                <label htmlFor={list?.mailId}></label>
                            </div>
                        </td>
                        <td onClick={()=>{onOpen(true, 'templatePreview', list?.mailId); setTempalte({templateId : list?.mailTemplateId , templateContents : list?.mailTemplateContents})}}>
                            <span>{list?.campaignTarget}</span>
                        </td>
                        <td onClick={()=>{onOpen(true, 'templatePreview', list?.mailId); setTempalte({templateId : list?.mailTemplateId , templateContents : list?.mailTemplateContents})}}>
                            <span>{list?.mailSubject}</span>
                        </td>
                        <td onClick={()=>{onOpen(true, 'templatePreview', list?.mailId); setTempalte({templateId : list?.mailTemplateId , templateContents : list?.mailTemplateContents})}}>
                            <div className="reservationBox">
                                {list?.reserveStatus === 'N' ? 
                                <span>
                                    <Moment format="YYYY.MM.DD HH시 mm분">{list?.mailSendDate}</Moment>
                                </span>
                                :
                                <>
                                <span className="purple">예약</span>
                                <span>
                                    <Moment format="YYYY.MM.DD HH시 mm분">{list?.reserveDate}</Moment>
                                </span>
                                </>
                                }
                            </div>
                        </td>
                        {list?.reserveStatus === 'Y' &&
                        <td>
                            <div className="btnBox">
                                <button>수정</button>
                                <button>취소</button>
                            </div>
                        </td>
                        }
                        <td onClick={()=>onOpen(true, 'diplayuser', list?.mailId)}>
                            <div className="openMail">
                                <span>{list?.mailTotalOpenCnt}</span><span>/</span><span>{list?.mailReceiverCnt}</span><span>건</span><span> ({list?.mailTotalOpenRate})</span>
                            </div>
                        </td>
                        <td onClick={()=>onOpen(true, 'clickuser', list?.mailId)}>
                            <div className="clickMail">
                                <span>{list?.mailTotalClickCnt}</span><span>/</span><span>{list?.mailReceiverCnt}</span><span>번</span><span> ({list?.mailTotalClickRate})</span>
                            </div>
                        </td>
                        {isSentMail && 
                        <>
                        <td></td>
                        <td>
                            {list?.reserveStatus === 'N' ?
                            '':
                            <div className="btnBox">
                                <button>수정</button>
                                <button>취소</button>
                            </div>
                            }
                        </td>
                        </>
                        }
                    </tr>
                )
            })}
        </tbody>
        </>
    )
} 