import React, { useEffect, useState } from "react";
export interface Props {data : any , setData : Function}
export const MailButtonBox:React.FC<Props> = ({data, setData}) => {
    const handleChange = (e:any) => {
        const {name, value} = e.target
        setData((prev:any)=>({...prev, [name] : value}))
    }
    
    return(
        <>
        <tr className="button">
            <th>버튼 사용여부</th>
            <td>
                <div className="flexBox">
                    <div className="flexBox radioList">
                        <div className="radioBox" onClick={()=>setData((prev:any)=>({...prev, btnClass : 'N'}))}>
                            <input type="radio" name="" id="none" checked={data?.btnClass === 'N' ? true : false}/>
                            <label htmlFor="none">버튼 미사용</label>
                        </div>
                        <div className="radioBox" onClick={()=>setData((prev:any)=>({...prev, btnClass : 'Y'}))}>
                            <input type="radio" name="" id="active" checked={data?.btnClass === 'Y' ? true : false}/>
                            <label htmlFor="active">버튼 사용</label>
                        </div>
                    </div>

                    <div className="use_button active">
                        <div className="inputBox">
                            <input type="text" name="btnLabel" id="" onChange={handleChange} disabled={data?.btnClass === 'N' ? true : false} placeholder="Button Name"/>
                        </div>
                        <div className="inputBox">
                            <input type="text" name="btnLink" id="" onChange={handleChange} disabled={data?.btnClass === 'N' ? true : false} placeholder="Button Link (ex. https://www.naver.com/)"/>
                        </div>
                    </div>
                    {/* <!-- 버튼 사용을 눌렀을 때 active클래스 붙으면 활성화 됨. --> */}
                </div>
            </td>
        </tr>
        </>
    )
}