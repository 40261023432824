import React, { useEffect, useLayoutEffect, useState } from "react";
import { SettingToolBox } from "../../../components/setting/settingToolBox";
import { TabBox } from "../../../components/item/tabBox";
import { Company } from "./category/company";
import { Employee } from "./category/employee";
import { Sender } from "./category/sender";
import { useParams } from "react-router-dom";
import { Paginate } from "../../../components/paginate/paginate";
import { useGetCompanyInfoMutation, useGetManagerMutation, useGetSenderListMutation } from "../../../store/slice/settingApiSlice";
import { AddEmployeePopup } from "../../../components/setting/popup/addEmployeePopup";
import { AddSendorPopup } from "../../../components/setting/popup/addSendorPopup";
import { useAppSelector } from "../../../store/storeHooks";
export const Setting = () => {
    const {settingName} = useParams()
    const userInfo = useAppSelector(state=>state.loginUser.userInfo)
    const [getManagerListApi] = useGetManagerMutation()
    const [getCompanyInfo] = useGetCompanyInfoMutation()
    const [getSenderList] = useGetSenderListMutation()
    const handlePage = (pageNumber:number)=>{}
    const [data, setData] = useState<any>([])
    const [param, setParam] = useState<any>({managerUuid : userInfo?.userId, managerId : userInfo?.apiId, page : 1, size : 10, keyword : '', column : 'managerName', order : 'desc'})
    const [totalCount, setTotalCount] = useState<number>(0)
    const [popUp, setPopup] = useState<any>({isOpen : false, type : '', id : ''})
    const [selectMember, setSelectMember] = useState<any>([])
    const handleOpen = (isOpen:boolean,type:string,id:any) => {setPopup({isOpen:isOpen,type:type,id:id})}
    const handleClose = () => {setPopup({isOpen:false, type:'', id:''}); getList()}
    const getList = async() => {
        if(settingName === 'employee'){
            const result : any = await getManagerListApi(param)
            if(result.data.result === true){setData(result.data.list)}
        }
        if(settingName === 'senders'){
            const result : any = await getSenderList(param)
            if(result.data.result === true){
                setData(result.data.list)
            }
        }
    }
    const getCompany = async() => {
        const formData : any = new FormData()
        formData.append('managerUuid' , userInfo?.userId)
        const result : any = await getCompanyInfo(formData)
        if(result.data.result === true){setData(result.data.list)}else{alert(result.data.resultMsg)}
    }
    useEffect(()=>{setSelectMember([])}, [settingName])
    useEffect(()=>{setParam((prev : any)=>({...prev, managerUuid : userInfo?.userId, managerId : userInfo?.apiId}))}, [userInfo])
    useEffect(()=>{
        if(settingName === 'company' && userInfo?.userId) getCompany()
    }, [settingName, param.managerUuid])
    useLayoutEffect(()=>{getList()}, [settingName, param.managerId])
    return(
        <>
        <div className={`contentBox setting ${settingName}`}>
            <div>
                <h2>Setting</h2>
                <TabBox/>
                <SettingToolBox
                    refetch={getList}
                    isOpen={handleOpen} 
                    selectMember={selectMember}
                />
                <div className="flexWrap">
                    <div className="tabContentBox">
                        {settingName === 'company' ? 
                        <Company
                            refetch={getCompany}
                            detail={data[0]}
                        />
                        :''
                        }
                        {settingName === 'employee' ? 
                        <Employee 
                            data={data}
                            onOpen={handleOpen}
                            selectManger={selectMember}
                            setManager={setSelectMember}
                        />
                        : ''
                        }
                        {settingName === 'senders' ?
                        <Sender
                            data={data}
                            selectManager={selectMember}
                            setManager={setSelectMember}
                        />
                        : ''
                        }
                    </div>
                    <div className="pagerBox">
                        <Paginate page={1} totalCount={10} size={10} onPage={handlePage}/>
                        <button className="purpleBtn">회원 추가하기</button>
                    </div>
                </div>
            </div>
        </div>
        {popUp.isOpen && popUp.type === 'addEmployee' ? <AddEmployeePopup id={popUp?.id} onClose={handleClose}/> : ''}
        {popUp.isOpen && popUp.type === 'addSendor' ? <AddSendorPopup onClose={handleClose} /> : ''}
        {popUp.isOpen && popUp.type === 'modifyEmployee' ? <AddEmployeePopup id={popUp?.id} onClose={handleClose}/> : ''}
        </>
    )
}