import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDelTemplateMutation } from "../../store/slice/templateApiSlice";
import toast, { Toaster } from "react-hot-toast";
export interface Props {refetch : Function, selectedTemplate : any, setSelectTemplate : Function}
export const TemplateToolBox:React.FC<Props> = ({refetch, selectedTemplate, setSelectTemplate}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchRef = useRef<any>(null)
    const page : any = new URLSearchParams(location.search).get('page') ;
    const keyword : any = new URLSearchParams(location.search).get('keyword');
    const order : any = new URLSearchParams(location.search).get('order');
    const column : any = new URLSearchParams(location.search).get('column');
    const size = new URLSearchParams(location.search).get('size') || 10;
    const [delTemplate] = useDelTemplateMutation()
    const handleDelTemplate = async() => {
        if(selectedTemplate?.length === 0){
            toast.error(errorUndoButton , {style : errorStyle}); return;
        }else{
            const formData : any = new FormData()
            formData.append('templateId', selectedTemplate)
            const result : any = await delTemplate(formData)
            if(result.data.result === true){
                toast.success(successUndoButton, {style : seccessStyle})
                refetch(); setSelectTemplate([])
            }else{alert(result.data.resultMsg)}
        }
    }
    const handleSearch = (e:any) => {
        if(e.key === 'Enter'){
            navigate(`${location.pathname}?page=${page}&size=${size}&keyword=${searchRef.current.value}&column=${column}&order=${order}`)
        }
    }
    return(
        <>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />
        <div className="toolBox">
            <div className="left">
                <div className="selectBox">
                    <select name="" value={size} id="" onChange={(e)=>navigate(`${location.pathname}?page=${page}&size=${e.target.value}&keyword=${keyword}&column=${column}&order=${order}`)}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                    </select>
                </div>
                <button className="deleteBtn" onClick={handleDelTemplate}>
                    <span>삭제</span>
                </button>
            </div>
            <div className="right">
                <div className="searchBox">
                    <div>
                        <span></span>
                        <input ref={searchRef} type="text" defaultValue={keyword} onKeyDown={handleSearch} placeholder="Search..."/>
                    </div>
                </div>
                <button className="mailTemplateAddBtn" onClick={()=>navigate('/template/add')}>
                    <span>템플릿 추가하기</span>
                </button>
            </div>
        </div>
        </>
    )
}

const successUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        탬플릿을 삭제하였습니다.
    </span>
    </div>
);


const seccessStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid #F79E00',
}

const errorUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        템플릿을 선택하셔야 합니다.
    </span>
    </div>
);

const errorStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid red',
}