import React from 'react'
import { SizeBox } from '../item/sizeBox'
import { useDelSenderMutation } from '../../store/slice/settingApiSlice'
export interface Props {isOpen : Function, selectMember : any, refetch : Function}
export const SettingToolBox:React.FC<Props> = ({isOpen, selectMember, refetch}) => {
    const [delSender] = useDelSenderMutation()
    const handleDelSender = async() => {
        const formData : any = new FormData()
        formData.append('sendorId', selectMember)
        const result : any = await delSender(formData)
        if(result.data.result === true){refetch()}
        else{alert(result.data.resultMsg)}
    }
    return(
        <>
        <div className="toolBox">
            <div className="left">
                <SizeBox/>
                <button className="deleteBtn" onClick={handleDelSender}>
                    <span>삭제</span>
                </button>
            </div>
            <div className="right">
                <div className="searchBox">
                    <div>
                        <span></span>
                        <input type="text" placeholder="Search..."/>
                    </div>
                </div>
                <button className="memberAddBtn" onClick={()=>isOpen(true, 'addEmployee')}>
                    <span>직원 추가하기</span>
                </button>
                
                <button className="mailAddBtn" onClick={()=>isOpen(true, 'addSendor')}>
                    <span>메일 추가하기</span>
                </button>
            </div>
        </div>
        </>
    )
}