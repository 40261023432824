import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DeletedMailPopup } from "../../../components/mailBox/popUp/deletedMailPopup";
import { MailBoxFilter } from "../../../components/mailBox/mailBoxFilter";
import { MailBoxTab } from "../../../components/mailBox/mailBoxTab";
import { Paginate } from "../../../components/paginate/paginate";
import { useGetSentMailListMutation } from "../../../store/slice/sentmailApiSlice";
import { useAppSelector } from "../../../store/storeHooks";
import { MemberListFilter } from "../member/category/list-filter";
import { MailBoxList } from "./list";
import { ReserveCancelPopup } from "../../../components/mailBox/popUp/reserveCancelPopup";
import { TemplatePreviewPopup } from "../../../components/mailBox/popUp/templatePreviewPopup";
import { DisplayUserPopup } from "../../../components/mailBox/popUp/displayUserPopup";
import { ClickUserPopup } from "../../../components/mailBox/popUp/clickUserPopup";
export interface PageInfoData {className : string, totalCount : number}
export interface PopupData {isOpen : boolean, type : string, mailId : any}
export const MailBox = () => {
    const {category} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const page : any = new URLSearchParams(location.search).get('page') || 1
    const keyword : any = new URLSearchParams(location.search).get('keyword') || ''
    const column : any = new URLSearchParams(location.search).get('column') || 'mailSubject'
    const order : any = new URLSearchParams(location.search).get('order') || 'desc'
    const isSentMail = category === 'sentMail' ? true : false
    const userInfo = useAppSelector(state => state.loginUser.userInfo)
    const [getSentMailList] = useGetSentMailListMutation()
    const [param, setParam] = useState<any>({managerUuid : userInfo?.userId, page : page, size : 10, keyword : keyword, column : column, order : order})
    const [data, setData] = useState<any>([])
    const [selectMail, setSelectMail] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [popUp, setPopUp] = useState<PopupData>({isOpen : false, type : '', mailId : ''})
    const [template , setTempalte] = useState<{templateId : string, templateContents : string}>({
        templateId : '', templateContents : ''
    })
    const handleOpen = (isOpen : boolean, type : string, mailId : any) => {setPopUp({isOpen : isOpen, type : type, mailId : mailId})}
    const handleClose = () => {setPopUp({isOpen : false , type : '', mailId : ''})}
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        if(name === 'all'){
            if(data){
                if(checked){setSelectMail(data.map((mail:any)=>mail.mailId.toString()))}
                else{setSelectMail([])}
            }
        }else{
            if(checked){setSelectMail((prev:any)=>[...prev, e.target.id])}
            else {setSelectMail((prev:any)=>prev.filter((id:any)=>id !== e.target.id))}
        }
    }
    const handlePage = (pageNumber: number) => {
        navigate(`${location.pathname}?page=${pageNumber}&size=10&keyword=${keyword}&column=${column}&order=${order}`)
    }
    const getList = async() => {
        const result : any = await getSentMailList(param)
        if(result.data.result === true) setData(result.data.list); setTotalCount(result.data.total_cnt)
    }
    useEffect(()=>{setParam((prev:any)=>({...prev, managerUuid : userInfo?.userId, page : page, keyword : keyword, column : column, order : order}))}, [userInfo, page, keyword, column, order])
    useEffect(()=>{
        if(userInfo?.userId) getList()
    },[category, param.managerUuid, param.page, param.keyword, param.column, param.order])
    return(
        <>
        <div className={category === 'sentMail'?'contentBox sentMail':'contentBox deleted_mail'}>
            <div>
                <MailBoxTab onOpen={handleOpen} keyword={keyword}/>

                <div className="flexWrap">
                    <div className="tabContentBox">

                        <div className={category === 'sentMail'?'sentMail':'deleted_mail'}>
                            <div className="tableWrap">
                                <table className="tableType_a">
                                    <MemberListFilter
                                        data={data}
                                        selectedMember={selectMail}
                                        onAllCheck={handleChange}
                                    />
                                    <MailBoxList
                                        data={data}
                                        selectMail={selectMail}
                                        isSentMail={isSentMail}
                                        onChange={handleChange}
                                        onOpen={handleOpen}
                                        setTempalte={setTempalte}
                                    />
                                </table>
                            </div>
                        </div>
                        {/* <!-- sentMail Fin --> */}
                    </div>

                </div>


                <div className="pagerBox">
                    <Paginate page={page} size={10} totalCount={totalCount} onPage={handlePage} />
                    {/* <button className="purpleBtn">회원 추가하기</button> */}
                </div>
                {/* <!-- pagerBox --> */}
            </div>
        </div>
        {popUp.isOpen && popUp.type === 'deleted' ? <DeletedMailPopup onClose={handleClose}/> : ''}
        {popUp.isOpen && popUp.type === 'reserveCancel' ? <ReserveCancelPopup/> : ''}
        {popUp.isOpen && popUp.type === 'templatePreview' ? <TemplatePreviewPopup info={popUp} template={template} onClose={handleClose}/> : ''}
        {popUp.isOpen && popUp.type === 'diplayuser' ? <DisplayUserPopup info={popUp} onClose={handleClose}/> : ''}
        {popUp.isOpen && popUp.type === 'clickuser' ? <ClickUserPopup info={popUp} onClose={handleClose}/> : ''}
        </>
    )
}