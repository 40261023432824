import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastUIEditor } from "../../../components/editor/toast-ui-editor";
import { TemplateCreateEditor } from "../../../components/editor/template-create-editor";
import { useGetTemplateDetailMutation, useSetTemplateMutation, useUpdTemplateMutation } from "../../../store/slice/templateApiSlice";
import { useAppSelector } from "../../../store/storeHooks";

export const TemplateAdd = () => {
    const userInfo : any = useAppSelector(state=>state.loginUser.userInfo)
    const {templateId} = useParams()
    const navigate = useNavigate()
    const [getTemplateDetail] = useGetTemplateDetailMutation()
    const [setTemplate] = useSetTemplateMutation()
    const [updTemplate] = useUpdTemplateMutation()
    const [isActive, setActive] = useState<number>(1)
    const [data, setData] = useState<any>({templateName : '', templateTop : '', templateButton : '', templateFooter : '', templateImage : '', templateImageFilename : ''})
    const getDetail = async() => {
        const result : any = await getTemplateDetail({templateId : templateId})
        if(result.data.result === true){setData(result.data.list[0])}
    }
    const handleChange = (e:any) => {
        const {type, name, value} = e.target;
        if(type === 'file'){
            const reader = new FileReader();
            if(e.target.files[0]){reader.readAsDataURL(e.target.files[0])}
            reader.onload=()=>{
                setData((prev:any)=>({...prev, templateImage : e.target.files[0], templateImageFilename : e.target.files[0].name}));
            }
        }else{
            setData((prev:any)=>({...prev, [name] : value}))
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(templateId !== 'add'){formData.append('templateId', templateId)}
        formData.append('managerUuid', userInfo.userId)
        formData.append('templateName', data?.templateName)
        formData.append('templateTop', data?.templateTop)
        formData.append('templateButton', data?.templateButton)
        formData.append('templateFooter', data?.templateFooter)
        if(data?.templateImageFile){
            formData.append('templateImage', data?.templateImage)
        }
        if(templateId !== 'add'){
            const result : any = await updTemplate(formData)
            if(result.data.result === true){
                navigate(-1)
            }else{alert(result.data.resultMsg)}
        }else{
            const result : any = await setTemplate(formData)
            if(result.data.result === true){
                navigate('/template')
            }else{alert(result.data.resultMsg)}
        }
    }
    useEffect(()=>{
        if(templateId!=='add'){getDetail()}
    },[templateId])
    return(
        <>
        <div>
            <h2>Template</h2>
            <div className="tabBtnBox">
                <button className="active">Add Template</button>
            </div>

            <div className="table">
                <div>
                    <span>Template Title</span>
                    <div className="inputBox">
                        <input type="text" name="templateName" value={data?.templateName} onChange={handleChange} id="" placeholder="템플릿 명을 입력해 주세요" required/>
                    </div>
                </div>
                <div>
                    <span>Thumbnail</span>
                    <div className="fileUploadBox">
                        <input type="file" name="" id="fileUpload" onChange={handleChange} required={data?.templateImageFilename === '' ? true : false}/>
                        <label htmlFor="fileUpload">이미지 파일을 업로드해 주세요.</label>
                        <button></button>
                        <span className="fileName">{data?.templateImageFilename}</span>
                    </div>
                </div>
                <div>
                    <span>Code</span>
                    <div className="tabBtnBox">
                        <button className={isActive===1?'active':''} onClick={()=>setActive(1)}>Top</button>
                        <button className={isActive===2?'active':''} onClick={()=>setActive(2)}>Button</button>
                        <button className={isActive===3?'active':''} onClick={()=>setActive(3)}>Footer</button>
                    </div>
                    <div className="tabContentBox">
                        <div className={`top ${isActive===1?'active':''}`}>
                            <TemplateCreateEditor initData={data?.templateTop} name="templateTop" setData={setData}/>
                        </div>
                        <div className={`button ${isActive===2?'active':''}`}>
                            <TemplateCreateEditor initData={data?.templateButton} name="templateButton" setData={setData}/>
                        </div>
                        <div className={`footer ${isActive===3?'active':''}`}>
                            <TemplateCreateEditor initData={data?.templateFooter} name="templateFooter" setData={setData}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="btnBox">
                <button className="purpleBorderBtn" onClick={()=>navigate(-1)}>취소</button>
                <button className="purpleBtn" onClick={()=>handleSave()}>저장</button>
            </div>
        </div>
        </>
    )
}