import React from "react";
export interface Props {onClose : Function}
export const DeletedMailPopup:React.FC<Props> = ({onClose}) => {

    return(
        <>
        <div className="alertPopUp popUp_active active">
            <div>
                <div>
                    <p>
                        영구삭제 시 지워진 메일은 복구할 수 없습니다.
                        <strong>정말로 선택한 메일을 삭제하시겠습니까?</strong>
                    </p>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="redBtn">삭제</button>
                </div>
            </div>
        </div>
        </>
    )
}