import React, { useEffect, useState } from "react";
import { useGetTempStorageMailMutation } from "../../../store/slice/temporaryApiSlice";
import { useAppSelector } from "../../../store/storeHooks";
import { Paginate } from "../../../components/paginate/paginate";
import { useLocation } from "react-router-dom";
import { TempoararyToolBox } from "../../../components/temporary/temporaryToolBox";
import { MemberListFilter } from "../member/category/list-filter";

export const TempoararyMail = () => {
    const location = useLocation()
    const userInfo : any = useAppSelector(state => state.loginUser.userInfo)
    const page : any = new URLSearchParams(location.search).get('page') || 1;
    const size : any = new URLSearchParams(location.search).get('size') || 10;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const column : any = new URLSearchParams(location.search).get('column') || 'mailSubject';
    const order : any = new URLSearchParams(location.search).get('order') || 'desc';
    const [selectMail, setSelectMail] = useState<any>([])
    const [getTempStorageMail] = useGetTempStorageMailMutation()
    const [param, setParam] = useState<any>({managerId : userInfo?.userId, page : page, size : size, keyword : keyword, sortColumn : column, sortOrder : order})
    const [data, setData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const getList = async() => {
        const result : any = await getTempStorageMail(param)
        if(result.data.result === true){setData(result.data.list); setTotalCount(result.data.total_cnt)}
        else{alert(result.data.resultMsg)}
    }
    const handlePage = (pageNumber : number) => {

    }
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        if(name === 'all'){
            if(data){
                if(checked){setSelectMail(data.map((temp:any)=>temp.sendorId.toString()))}
                else{setSelectMail([])}
            }
        }else{
            if(checked){setSelectMail((prev:any)=>[...prev, e.target.id])}
            else {setSelectMail((prev:any)=>prev.filter((id:any)=>id !== e.target.id))}
        }
    }
    useEffect(()=>{setParam((prev:any)=>({...prev, managerId : userInfo?.userId, page:page, size:size, keyword:keyword, sortColumn:column, sortOrder:order}))}, [userInfo,page,size,keyword,order,column])
    useEffect(()=>{
        if(userInfo?.userId){
            getList()
        }
    }, [param.managerId,param.page,param.size,param.keyword,param.sortColumn,param.sortOrder])
    return(
        <>
        <div className="contentBox temporary_storage">
            <div>
                <h2>Temporary storage</h2>               
                <div className="tabBtnBox">
                    <button className="active">Mail list</button>
                </div>

                <TempoararyToolBox
                    refetch={getList}
                    selectMail={selectMail}
                    setSelectMail={setSelectMail}
                />
                {/* <!-- toolBox Fin --> */}

                <div className="flexWrap">

                    <div className="temporary_storage">
                        <div className="tableWrap">
                            <table className="tableType_a">
                                <MemberListFilter
                                    data={data}
                                    selectedMember={selectMail}
                                    onAllCheck={handleChange}
                                />
                                <tbody>
                                    {data?.map((list:any)=>(
                                    <tr key={list?.sendorId}>
                                        <td className="check">
                                            <div className="checkBox">
                                                <input type="checkbox" name="" id={list?.sendorId} checked={selectMail.includes(list.sendorId.toString())} onChange={handleChange}/>
                                                <label htmlFor={list?.sendorId}></label>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{list?.campaignTarget}</span>
                                        </td>
                                        <td>
                                            <span>{list?.mailSubject}</span>
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

    
                    <div className="pagerBox">
                        <Paginate
                            page={page} size={size} totalCount={totalCount} onPage={handlePage}
                        />
                    </div>
                    {/* <!-- pagerBox --> */}
                </div>
                {/* <!-- pagerBox 를 하단에 붙이기 위한 wrap --> */}
            </div>
        </div>
        </>
    )
}