import React, { useEffect, useState } from "react";
import { MemberList } from "../../member/category/list";
import { useGetMemberListMutation } from "../../../../store/slice/membersApiSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetMemberAddToGroupMutation } from "../../../../store/slice/groupApiSlice";
import { Paginate } from "../../../../components/paginate/paginate";
import { AddMemberToolBox } from "../../../../components/group/groupAddMemberToolBox";
import { GroupAddMemberTabBox } from "../../../../components/group/groupAddMemberTabBox";
import { useAppSelector } from "../../../../store/storeHooks";
interface ParamType {loginId : any, page : any, size : number, keyword : string, sortColumn : string, sortOrder : string, memberCheckInGroup : string, categoryId: any, groupId : any}
export interface Props {categoryParam:any, groupId: any, selectMember : any, setSelectMember: Function}
export const GroupAddList:React.FC<Props> = ({categoryParam, groupId, selectMember, setSelectMember}) => {
    const {category} = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const userId = useAppSelector((state) => state.loginUser.userInfo.apiId)
    const page : any = new URLSearchParams(location.search).get('page') || 1;
    const size : any = new URLSearchParams(location.search).get('size') || 10;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const order : any = new URLSearchParams(location.search).get('order') || 'desc';
    const column : any = new URLSearchParams(location.search).get('column') || 'memberName';
    const checkType : any = new URLSearchParams(location.search).get('type')
    const [param, setParam] = useState<ParamType>({loginId : userId, page: page, size : size, keyword : '', sortColumn : column,
    sortOrder : order, categoryId : categoryParam, groupId : groupId, memberCheckInGroup : checkType})
    const [data, setData] = useState<any>([])
    const [totalCount, setTotalCount]=useState<number>(0)
    const [categoryAndGroupName, setName] = useState<any>({categoryName : '', groupName : ''})
    const [getMemberList] = useGetMemberAddToGroupMutation()
    const getList = async() => {
        const result : any = await getMemberList(param)
        if(result.data.result === true){setData(result.data.list);setTotalCount(result.data.total_cnt);
        setName((prev:any)=>({...prev, categoryName : result.data.categoryName, groupName : result.data.groupName}))}
    }
    const handlePage = (pageNubmer: number) => {
        navigate(`${location.pathname}?category=${categoryParam}&groupId=${groupId}&type=${checkType}&page=${pageNubmer}&size=${size}&keyword=${keyword}&column=${column}&order=${order}`)
    }
    useEffect(()=>{setParam((prev)=>({...prev, loginId : userId, page : page, size : size, keyword : keyword, sortOrder : order, sortColumn : column, memberCheckInGroup : checkType}))}, [userId, page, size, keyword, order, column, checkType])
    useEffect(()=>{
        if(param.loginId){
            getList()
        }
        setSelectMember([])
    },[category, param.loginId, param.page, param.size, param.keyword, param.sortColumn, param.sortOrder, param.memberCheckInGroup])
    const handleOpen = () => {}
    return(
        <>
        <GroupAddMemberTabBox
            refetch={getList}
            name={categoryAndGroupName}
        />

        <AddMemberToolBox
            refetch={getList}
            selectMember={selectMember}
            setSelectMember={setSelectMember}
        />

        <div className="flexWrap">
            <div className="group_memberAdd">
                <MemberList
                    data={data}
                    getList={getList}
                    selectMember={selectMember}
                    setMember={setSelectMember}
                    isOpen={handleOpen}
                />
                <div className="pagerBox">
                    <Paginate page={page} size={10} totalCount={totalCount} onPage={handlePage}/>
                    <button className="purpleBtn" onClick={()=>navigate('/groups/group?category=1&page=1&size=10&keyword=&column=groupName&order=desc')}>목록으로</button>
                </div>
            </div>
        </div>
        </>
    )
}