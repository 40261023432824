import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
export interface Props {
    uploadCount : number
    totalCount : number
    onClose : Function
}
export const ExcelUploadProgressPopup:React.FC<Props> = ({uploadCount, totalCount, onClose}) => {
    const [percent, setPercent] = useState<number>(0)
    useEffect(()=>{
        setPercent(Math.floor((uploadCount/totalCount) * 100))
    }, [uploadCount])
    return(
        <>
        <div className="progressPopUp popUp_active active">
            <div className="excelUpload">
                <div>
                    <div>
                        <h3>Excel 파일 업로드{percent}</h3>
                        <div className="progressBox">
                            <div>
                                <div>
                                    <span className="purple">{uploadCount}명</span>
                                    <span>{totalCount}명</span>
                                </div>
                                
                                {/* <div>
                                    <span>전송속도 00.0MB/초</span>
                                    <span>8초 남음</span>
                                </div> */}
                            </div>
                                <ProgressBar completed={percent} />
                            <div>
                                {/* <span className="numb">25%</span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {percent === 100 ? 
                    <button className="cancleBtn" onClick={()=>onClose()}>닫기</button>
                    : ''
                    }
                    {/* <button className="yellowBtn">일시정지</button> */}
                </div>
            </div>
        </div>
        </>
    )
}