import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../../store/slice/userApiSlice";
import { setuserInfo } from "../../../store/slice/userInfoSlice";
import { useAppDispatch } from "../../../store/storeHooks";
import { MainFooter } from "../mainFooter";
import { MainBtnBox } from "../mainBtnBox";
interface LoginData {id:string,password:string,saveCheck:boolean}
export const Login = () => {
    const dispatch = useAppDispatch()
    const [loginApi] = useLoginMutation()
    const navigate = useNavigate()
    const localStorageId = localStorage.getItem('ACT')
    const [data, setData] = useState<LoginData>({id : localStorageId ? localStorageId : '', password : '', saveCheck : localStorageId ? true : false})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {type, name, value, checked} = e.target;
        if(type === 'checkbox'){setData((prev) => ({...prev, [name] : checked}))}
        else {setData((prev) => ({...prev, [name] : value}))}
    }
    const handleLogin = async() => {
        const formData : any = new FormData()
        formData.append('managerEmail', data.id)
        formData.append('managerPass', data.password)
        const result : any = await loginApi(formData)
        if(result.data.result === true){
            // localStorage
            if(data?.saveCheck){ localStorage.setItem('ACT', data?.id)}
            else{localStorage.removeItem('ACT')}
            // session Storage
            sessionStorage.setItem('keys', result.data.managerUuid)
            // store 
            dispatch(setuserInfo({userId : result.data.managerUuid, account : result.data.managerEmail,
            name : result.data.managerName, isAdmin : result.data.managerAdmin, apiId : result.data.ID, companyId : result.data.companyId}))
            navigate('/member/individual?page=1&size=10&keyword=&column=memberName&order=asc');
        }else{
            alert("아이디 및 비밀번호를 확인해주세요.");
        }
    }
    const handleEnter = (e:React.KeyboardEvent<HTMLInputElement>) =>{
        if(e.key === 'Enter'){handleLogin()}
    }
    
    return(
        <>
        <div className="loginWrap login">
            <div className="loginBox">
                <div>
                    <div className="left">
                        <span>Mail To :)</span>
                    </div>
                    <div className="right">
                        <div>
                            <h1>Mail To :)</h1>
                            <span>Digital Marketing Mailing Solution</span>
                            <div>
                                <div className="inputBox">
                                    <span className="icon_login"></span>
                                    <input type="text" value={data?.id} name="id" placeholder="아이디 (이메일)" onChange={handleChange} onKeyDown={handleEnter} required/>
                                </div>
                                <div className="inputBox">
                                    <span className="icon_pw"></span>
                                    <input type="password" value={data?.password} name="password" placeholder="비밀번호" onChange={handleChange} onKeyDown={handleEnter} required/>
                                </div>
                                <div className="checkBox">
                                    <input type="checkbox" name="saveCheck" id="id_save" checked={data?.saveCheck ? true : false} onChange={handleChange}/>
                                    <label htmlFor="id_save">아이디 저장</label>
                                </div>
                                <button className="purpleBtn" onClick={handleLogin}>로그인</button>
                                <MainBtnBox/>
                            </div>
                        </div>
                    </div>
                </div>
                <MainFooter/>
            </div>
        </div>
        </>
    )
}
