import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MemberPageInfo } from "..";
import { ToggleBox } from "../../../../components/item/toggleBox";
import { useUpdMemberActiveStatusMutation } from "../../../../store/slice/membersApiSlice";
import { MemberListFilter } from "./list-filter";
export interface Props {data: any, getList : Function,setMember : Function, isOpen : Function, selectMember : any}
export const MemberList:React.FC<Props> = ({data, getList,setMember, isOpen, selectMember}) => {
    const {category} = useParams()
    const [updMemberActiveStatusApi] = useUpdMemberActiveStatusMutation()
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        if(name === 'all'){
            if(data){
                if(checked){setMember(data.map((member:any)=>member.memberId.toString()))}
                else{setMember([])}
            }
        }else{
            if(checked){setMember((prev:any)=>[...prev, e.target.id])}
            else {setMember((prev:any)=>prev.filter((id:any)=>id !== e.target.id))}
        }
    }
    const handleChangeStatus = async(id : string, status : string) => {
        const formData : any = new FormData()
        formData.append('memberId' , id)
        formData.append('memberActiveStatus', status === 'Y' ? 'N' : 'Y')
        const result : any = await updMemberActiveStatusApi(formData)
        if(result.data.result === true){
            getList()
        }else{alert(result.data.resultMsg)}
    }
    useEffect(()=>{setMember([])},[category])
    
    return(
        <>
        <div className="tableWrap">
            <table className="tableType_a">
                <MemberListFilter 
                    data={data} 
                    selectedMember={selectMember} 
                    onAllCheck={handleChange}
                />
                <tbody>
                    {data?.map((list:any, index : number) => (
                    <tr key={index}>
                        <td className="check">
                            <div className="checkBox">
                                <input type="checkbox" name="" id={list?.memberId} checked={selectMember?.includes(list.memberId.toString())} onChange={handleChange}/>
                                <label htmlFor={list?.memberId}></label>
                            </div>
                        </td>
                        <td onClick={()=>isOpen(true, 'editMember', list)}>
                            <span>{list?.memberName}</span>
                        </td>
                        <td onClick={()=>isOpen(true, 'editMember', list)}>
                            <span>{list?.memberEmail}</span>
                        </td>
                        <td onClick={()=>isOpen(true, 'editMember', list)}>
                            <span>{list?.memberMobile}</span>
                        </td>
                        <td onClick={()=>isOpen(true, 'editMember', list)}>
                            <span>{list?.memberDept}</span>
                        </td>
                        <td onClick={()=>isOpen(true, 'editMember', list)}>
                            <span>{list?.memberPosition}</span>
                        </td>
                        <td onClick={()=>isOpen(true, 'editMember', list)}>
                            <span>{list?.memberNote}</span>
                        </td>
                        {category === 'individual' ? 
                        <td>
                        <ToggleBox name={'member'} onChange={handleChangeStatus} id={list?.memberId} status={list?.memberActiveStatus}/>
                        </td>
                        : ''}
                    </tr>
                    ))}
                </tbody>
            </table>
        </div>
        {/* <EditMemberPopup/> */}
        </>
    )
}