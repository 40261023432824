import React, { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const TabBox = () => {
    const {category} = useParams()
    const location = useLocation()
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const [tab, setTab] = useState<any>([])
    const handleTransTab = (secondUrl : string, url : string) => {
        if(secondUrl !== path[2]){
            navigate(url)
        }
    }
    useLayoutEffect(()=>{
        switch(path[1]){
            case 'member' : setTab(MemberTab); break;
            case 'setting' : setTab(SettingTab); break;
        }
    }, [path[1]])
    return(
        <>
        <div className="tabBtnBox">
            {tab.map((tab:any)=>(
                <button className={path[2] === tab.className  ? 'active' : ''} onClick={()=>handleTransTab(tab.className, tab.url)}>{tab.title}</button>
            ))}
        </div>
        </>
    )
}

export const MemberTab = [
    {idx: 1, title : 'Individual', className : 'individual', url : '/member/individual?page=1&size=10&keyword=&column=memberName&order=asc'},
    {idx: 2, title : 'Deleted member', className : 'deleted', url : '/member/deleted?page=1&size=10&keyword=&column=memberName&order=asc'},
]

export const SettingTab = [
    {idx: 1, title : 'Company', className : 'company', url : '/setting/company'},
    {idx: 2, title : 'Employee', className : 'employee', url : '/setting/employee?page=1&size=10&keyword=&column=managerName&order=asc'},
    {idx: 2, title : 'Senders', className : 'senders', url : '/setting/senders?page=1&size=10&keyword=&column=managerName&order=desc'},
]