import React, { useRef } from "react";
import { useDelTempStorageMailMutation } from "../../store/slice/temporaryApiSlice";
import { useAppSelector } from "../../store/storeHooks";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {refetch : Function, selectMail : any, setSelectMail : Function}
export const TempoararyToolBox:React.FC<Props> = ({refetch, selectMail, setSelectMail}) => {
    const userInfo : any = useAppSelector(state=>state.loginUser.userInfo)
    const searchRef=useRef<any>(null)
    const location = useLocation()
    const page : any = new URLSearchParams(location.search).get('page') ;
    const keyword : any = new URLSearchParams(location.search).get('keyword');
    const order : any = new URLSearchParams(location.search).get('order');
    const column : any = new URLSearchParams(location.search).get('column');
    const size = new URLSearchParams(location.search).get('size') || 10;
    const navigate = useNavigate()
    const [delTemporaryMail] = useDelTempStorageMailMutation()
    const handleDelTemporary = async() => {
        if(selectMail.length === 0){
            toast.error(errorUndoButton, {style : errorStyle})
        }else{
            const formData : any = new FormData()
            formData.append('managerUuid', userInfo.userId)
            formData.append('tempStorageEmailId', selectMail)
            const result : any = await delTemporaryMail(formData)
            if(result.data.result === true){
                toast.success(successUndoButton , {style : seccessStyle})
                refetch(); setSelectMail([])
            }else{alert(result.data.resultMsg)}
        }
    }
    const handleSearch = (e:any) => {
        if(e.key === 'Enter'){
            navigate(`${location.pathname}?page=${page}&size=${size}&keyword=${searchRef.current.value}&column=${column}&order=${order}`)
        }
    }
    return(
        <>
        <Toaster
            position="bottom-right"
            reverseOrder={false}
        />
        <div className="toolBox">
            <div className="left">
                <div className="selectBox">
                    <select name="" id="" onChange={(e)=>navigate(`${location.pathname}?page=${page}&size=${e.target.value}&keyword=${keyword}&column=${column}&order=${order}`)}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                    </select>
                </div>
                <button className="deleteBtn" onClick={handleDelTemporary}>
                    <span>삭제</span>
                </button>
            </div>
            <div className="right">
                <div className="searchBox">
                    <div>
                        <span></span>
                        <input type="text" ref={searchRef} defaultValue={keyword} onKeyDown={handleSearch} placeholder="Search..."/>
                    </div>
                </div>
                <button className="writingMailBtn">
                    <span>메일쓰기</span>
                </button>
            </div>
        </div>
        </>
    )
}

const successUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        메일을 휴지통으로 이동했습니다.
    </span>
    </div>
);

const seccessStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
    border : '1px solid #F79E00',
}

const errorUndoButton = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>
        메일을 선택하셔야 합니다.
    </span>
    </div>
);

const errorStyle = {
    width : '300px', height : '40px',
    fontSize : '15px', color : '#F79E00',
    backgroundColor : '#F79E000D',
}