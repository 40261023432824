import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDelGroupMutation, useDelMemberInGroupMutation, useSetMemberInGroupMutation } from "../../store/slice/groupApiSlice";
import { useAppSelector } from "../../store/storeHooks";
export interface Props {refetch : Function, setGroupIdArray: Function, groupIdArray : any, onOpen : Function}
export const GroupToolBox:React.FC<Props> = ({refetch, setGroupIdArray, groupIdArray, onOpen}) => {
    const location = useLocation()
    const searchRef = useRef<any>(null)
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const category = new URLSearchParams(location.search).get('category')
    const groupId = new URLSearchParams(location.search).get('groupId')
    const checkType = new URLSearchParams(location.search).get('type')
    const page : any = new URLSearchParams(location.search).get('page') ;
    const keyword : any = new URLSearchParams(location.search).get('keyword');
    const order : any = new URLSearchParams(location.search).get('order');
    const column : any = new URLSearchParams(location.search).get('column');
    const size = new URLSearchParams(location.search).get('size') || 10;
    const navigate = useNavigate()
    const [delGroup] = useDelGroupMutation()
    const [setMemberInGroup] = useSetMemberInGroupMutation()
    const [delMemberInGroup] = useDelMemberInGroupMutation()
    //  그룹 삭제
    const handleDelGroup = async() => {
        const confirm = window.confirm("해당 그룹을 삭제하시겠습니까?")
        if(confirm){
            const formData : any = new FormData()
            formData.append('managerId', userInfo?.apiId)
            formData.append('categoryId', category)
            formData.append('groupId', groupIdArray)
            const result : any = await delGroup(formData);
            if(result.data.result === true){
                refetch(); setGroupIdArray([])
            }else{alert(result.data.resultMsg)}
        }
    }
    // 검색
    const handleSearch = (e:any) => {
        if(e.key === 'Enter'){
            navigate(`${location.pathname}?category=${category}&page=${page}&size=${size}&keyword=${searchRef.current.value}&column=${column}&order=${order}`)
        } 
    }
    return(
        <>
        <div className="toolBox">
            <div className="left">
                <div className="selectBox">
                    <select value={size} name="" id="" onChange={(e)=>navigate(`${location.pathname}?category=${category}&page=${page}&size=${e.target.value}&keyword=${keyword}&column=${column}&order=${order}`)}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="150">150</option>
                    </select>
                </div>
                <button className="deleteBtn" onClick={handleDelGroup}>
                    <span>삭제</span>
                </button>
                <button className="groupAddBtn">
                    <span>그룹추가</span>
                </button>
                <button className="writingMailBtn" onClick={()=>navigate(`/mails?categorys=${category}`)}>
                    <span>메일쓰기</span>
                </button>
                <button className="permanentDeleteBtn">
                    <span>영구삭제</span>
                </button>
                <button className="restoreBtn">
                    <span>복원</span>
                </button>

                <div className="txtBox">
                    
                </div>
            </div>
            <div className="right">
                <div className="selectBox">
                    <select name="" id="" disabled>
                        <option value="">Name</option>
                    </select>
                </div>
                <div className="searchBox">
                    <div>
                        <span></span>
                        <input ref={searchRef} type="text" defaultValue={keyword} onKeyDown={handleSearch} placeholder="Search..."/>
                    </div>
                </div>
                <button className="memberAddBtn">
                    <span>회원 추가하기</span>
                </button>
                <button className="groupPlusBtn" onClick={()=>onOpen(true,'addGroup')}>
                    <span>그룹 추가하기</span>
                </button>
            </div>
        </div>
        </>
    )
}