import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../layout/footer/footer";
import Header_Pc from "../layout/header/pc";
import { Group } from "./group";
import { MailBox } from "./mailBox";
import { Mails } from "./mails";
import { Member } from "./member";
import { SideBar } from "./sidebar/sideBar";
import { TempoararyMail } from "./temporary";
import { Setting } from "./setting";
import { useGetMemberCategoryMutation } from "../../store/slice/membersApiSlice";
import { Template } from "./template";
import { useAppSelector } from "../../store/storeHooks";

export const Dashboard = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')
    const [param, setParam] = useState<any>({loginId : null})
    const userId : any = useAppSelector((state) => state.loginUser.userInfo.apiId)
    const [categoryList, setCategoryList] = useState<any>([])
    const [getCategoryListApi] = useGetMemberCategoryMutation()
    const getCategoryList = async() => {
        const result : any = await getCategoryListApi({loginId : param.loginId})
        if(result.data.result===true){setCategoryList(result.data.list)}
    }
    useEffect(()=>{
        setParam({loginId : userId})
    }, [userId])
    useEffect(()=>{
        if(param.loginId !== null && param.loginId){
            getCategoryList()
        }
    }, [param.loginId])
    return(
        <>
        <Header_Pc/>
        <SideBar categoryList={categoryList}/>
            {path[1] === 'member' ? <Member categoryList={categoryList} getCategoryList={getCategoryList}/> : ''}
            {path[1] === 'groups' ? <Group categoryList={categoryList} getCategoryList={getCategoryList}/> : ''}
            {path[1] === 'mails' ? <Mails/> : ''}
            {path[1] === 'mailbox' ? <MailBox/> : ''}
            {path[1] === 'temporary' ? <TempoararyMail/> : ''}
            {path[1] === 'template' ? <Template/> : ''}
            {path[1] === 'setting' ? <Setting/> : ''}
        <Footer/>
        </>
    )
}