import React from "react";
import { ToggleBox } from "../../../../components/item/toggleBox";
import { MemberListFilter } from "../../member/category/list-filter";
export interface Props {data : any,selectManager:any, setManager : Function}
export const Sender:React.FC<Props> = ({data, selectManager, setManager}) => {

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        if(name === 'all'){
            if(checked){setManager(data.map((sender:any)=>sender.sendorId.toString()))}
            else{setManager([])}
        }else{
            if(checked){setManager((prev:any)=>[...prev, e.target.id])}
            else {setManager((prev:any)=>prev.filter((id:any)=>id !== e.target.id))}
        }
    }

    const handleChangeActiveStatus = () => {

    }

    return(
        <>
        <div className="senders">
            <div className="tableWrap">
                <table className="tableType_a">
                    <MemberListFilter
                        data={data}
                        selectedMember={selectManager}
                        onAllCheck={handleChange}
                    />
                    <tbody>
                        {data?.map((list:any) => (
                        <tr key={list?.sendorId}>
                            <td className="check">
                                <div className="checkBox">
                                    <input type="checkbox" name="" id={list?.sendorId} checked={selectManager?.includes(list?.sendorId?.toString())} onChange={handleChange}/>
                                    <label htmlFor={list?.sendorId}></label>
                                </div>
                            </td>
                            <td>
                                <span>{list?.sendorName}</span>
                            </td>
                            <td>
                                <span>{list?.sendorEmail}</span>
                            </td>
                            <td>
                                <ToggleBox
                                    name={'sender'}
                                    id={list?.sendorId}
                                    status={list?.sendorActiveStatus}
                                    onChange={handleChangeActiveStatus}
                                />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}