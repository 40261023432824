import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const SizeBox = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const page : any = new URLSearchParams(location.search).get('page') ;
    const keyword : any = new URLSearchParams(location.search).get('keyword');
    const order : any = new URLSearchParams(location.search).get('order');
    const column : any = new URLSearchParams(location.search).get('column');
    const size = new URLSearchParams(location.search).get('size') || 10;
    return(
        <>
        <div className="selectBox">
            <select value={size} name="" id="" onChange={(e)=>navigate(`${location.pathname}?page=${page}&size=${e.target.value}&keyword=${keyword}&column=${column}&order=${order}`)}>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
            </select>
        </div>
        </>
    )
}