import React, { useState } from "react";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {onAdd : Function, onClose : Function}
export const AddCategoryPopup:React.FC<Props> = ({onAdd, onClose}) => {
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const [categoryName, setCategoryName] = useState<string>('')
    const handleCategoryAdd = () => {
        const formData : any = new FormData()
        formData.append('codeId', 'C')
        formData.append('codeCompanyId', userInfo?.companyId)
        formData.append('codeNameKr', categoryName)
        onAdd(formData)
    }
    return(
        <>
        <div className="alertPopUp popUp_active active">
            <div>
                <div>
                    <div className="editBox">
                        <input className="category" type="text" value={categoryName} onChange={(e)=>setCategoryName(e.target.value)} name="" id="test1" placeholder="카테고리명을 입력해 주세요." required/>
                        {/* <!-- <span></span> --> */}
                        <label htmlFor="test1"></label>
                    </div>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="blueBtn" onClick={handleCategoryAdd}>카테고리 추가</button>
                </div>
            </div>
        </div>
        </>
    )
}