import React from "react";

export const FindIdYAlert = () => {

    return(
        <>
        <div className="findPopUp popUp_active active">
            <div>
                <div>
                    <div>
                        <p>
                            입력하신 고객님의 정보와 일치하는 아이디는
                            <strong>aa98****@bmcnet.kr입니다.</strong>
                        </p>
                    </div>
                </div>
                <div className="type_a">
                    <button className="cancleBtn">비밀번호 찾기</button>
                    <button className="purpleBtn">로그인</button>
                </div>
            </div>
        </div>
        </>
    )
}

export const FindIdNAlert = () => {

    return(
        <>
        <div className="findPopUp popUp_active active">
            <div>
                <div>
                    <div>
                        <p>
                            입력하신 고객님의 정보와 일치하는 아이디가 없습니다.
                            <strong>가입 시 입력하신정보가 맞는지 다시 한번 확인해 주세요.</strong>
                        </p>
                    </div>
                </div>
                <div className="type_b">
                    <button className="purpleBtn">확인</button>
                </div>
            </div>
        </div>
        </>
    )
}