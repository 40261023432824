import React, { useEffect, useRef, useState } from "react";
import { useDelMemberInGroupMutation, useGetMemberListByGroupMutation, useUpdGroupNameMutation } from "../../../store/slice/groupApiSlice";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../store/storeHooks";
export interface Props {info:any, refetch : Function, onClose : Function}
export const EditGroupPopup:React.FC<Props> = ({info, refetch, onClose}) => {
    const location = useLocation()
    const userInfo : any = useAppSelector((state) => state.loginUser.userInfo)
    const category = new URLSearchParams(location.search).get('category')
    const focusRef = useRef<any>(null)
    const userId : any = useAppSelector((state) => state.loginUser.userInfo.apiId)
    const [isAcitve, setIsActive] = useState<boolean>(false)
    const [data, setData] = useState<any>({groupName : '', groupMembers : []})
    const [getMemberListByGroupApi] = useGetMemberListByGroupMutation()
    const [updGroupApi] = useUpdGroupNameMutation()
    const [delMemberInGroup] = useDelMemberInGroupMutation() 
    const getDetail = async() => {
        const param = {loginId : userId, groupId : info.groupInfo}
        const result : any = await getMemberListByGroupApi(param)
        if(result.data.result === true){
            setData((prev:any)=>({...prev, groupName : result.data.groupName, groupMembers : result.data.list}))
        }
    }
    const handleEditGroupName = async(e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter'){
            const formData : any = new FormData()
            formData.append('managerId', userInfo?.apiId)
            formData.append('groupId', info?.groupInfo)
            formData.append('groupName', data?.groupName)
            const result : any = await updGroupApi(formData)
            if(result.data.result === true){setIsActive(false); refetch()}
            else{alert(result.data.resultMsg)}
        }
    }
    const handleDelMember = async(memberId : any) => {
        const confirm : any = window.confirm("해당 회원을 삭제하시겠습니까?");
        if(confirm){
            const formData : any = new FormData()
            formData.append('managerId', userInfo?.apiId)
            formData.append('memberId', memberId)
            formData.append('categoryId', category)
            formData.append('groupId', info?.groupInfo)
            const result : any = await delMemberInGroup(formData)
            if(result.data.result === true){getDetail(); refetch()}
            else{alert(result.data.resultMsg)}
        }
    }
    useEffect(()=>{if(isAcitve){focusRef.current.focus()}},[isAcitve])
    useEffect(()=>{
        getDetail()
    }, [])

    return(
        <>
        <div className="addPopUp popUp_active active">
            <div className="groupName">
                <div>
                    <div>
                        <button><i className="fa-regular fa-xmark" onClick={()=>onClose()}></i></button>
                        <div className={isAcitve ? 'editBox active' : 'editBox'}>
                            <span className="" onClick={()=>setIsActive(true)}>{data?.groupName}</span>
                            {/* <!-- 클릭 시 editBox active 클래스 --> */}
                            <input 
                                ref={focusRef}
                                className="group" 
                                type="text" 
                                onChange={(e)=>setData((prev:any)=>({...prev, groupName : e.target.value}))}
                                onKeyDown={handleEditGroupName}
                                defaultValue={data?.groupName} 
                                id="test2"
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <span>회원</span>
                        <ul>
                            {data?.groupMembers?.map((member:any) => (
                            <li key={member?.memberId}>
                                <span>{member.memberName}</span>
                                <button onClick={()=>handleDelMember(member?.memberId)}><i className="fa-regular fa-xmark"></i></button>
                            </li>
                            ))}
                            <li></li>
                        </ul>
                        
                        <div>
                            <button className="memberAddBtn">
                                <span>회원 추가하기</span>
                            </button>
                            <button className="writingMailBtn">
                                <span>메일쓰기</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}