import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useAppSelector } from "../storeHooks";

const baseUrl = "https://dotsmail.net/api/controller";
export const temporaryApiSlice = createApi({
  reducerPath: "temporaryApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 임시메일 가져오기
    getTempStorageMail : builder.mutation({
      query : (param : {managerId : any, page : any, size : number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/dotsmail/getTempStorageEmail.php?managerUuid=${param.managerId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}`
        }
      }
    }),
    // 임시메일 삭제
    delTempStorageMail : builder.mutation({
      query : (body : any) => {
        return{
          url : '/dotsmail/delTempStorageEmail.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetTempStorageMailMutation, useDelTempStorageMailMutation
} = temporaryApiSlice;