import React from "react";
export interface Props {setData : Function}
export const TitleBox:React.FC<Props> = ({setData}) => {
    const handleChange = (e:any) => {
        setData((prev: any) => ({...prev, title : e.target.value}))
    }
    return(
        <>
        <tr>
            <th>
                <div className="flexBox">
                    <span>제목</span>
                    <div className="checkBox">
                        <input type="checkbox" name="" id=""/>
                        <label htmlFor="">
                            중요<span> !</span>
                        </label>
                    </div>
                </div>
            </th>
            <td>
                <div className="table_inputBox">
                    <input type="text" name="" onChange={handleChange} id="" placeholder="타이틀을 입력해 주세요."/>
                </div>
            </td>
        </tr>
        </>
    )
}