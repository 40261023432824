import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FindId } from "./user/findId";
import { FindPw } from "./user/findPw";
import { Login } from "./user/login";
import { SignUp } from "./user/signUp";

export const Main = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const pathname = location.pathname
    useEffect(()=>{if(pathname === '/'){navigate('/login')}}, [])
    const storeId = sessionStorage.getItem('key')
    useEffect(()=>{
        if(storeId){navigate('/member/individual')}
        else{navigate('/login')}
    }, [])
    return(
        <>
        <div id="wrap">
            {pathname === '/login' ? <Login/> : ''}
            {pathname === '/signup' ? <SignUp/> : ''}
            {pathname === '/findId' ? <FindId/> : ''}
            {pathname === '/findPw' ? <FindPw/> : ''}
        </div>
        </>
    )
}