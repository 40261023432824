import React from "react";
import { ToggleBox } from "../../../../components/item/toggleBox";
import { MemberListFilter } from "../../member/category/list-filter";
export interface Props {data : any, selectManger : any, setManager:Function, onOpen : Function}
export const Employee:React.FC<Props> = ({data, selectManger, setManager, onOpen}) => {

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target;
        if(name === 'all'){
            if(checked){setManager(data.map((manager:any)=>manager.managerId.toString()))}
            else{setManager([])}
        }else{
            if(checked){setManager((prev:any)=>[...prev, e.target.id])}
            else {setManager((prev:any)=>prev.filter((id:any)=>id !== e.target.id))}
        }
    }

    const handleChangeStatus = () => {

    }
    return(
        <>
        <div className="employee">
            <div className="tableWrap">
                <table className="tableType_a">
                    <MemberListFilter
                        data={data}
                        selectedMember={selectManger}
                        onAllCheck={handleChange}
                    />
                    <tbody>
                        {data?.map((list:any,index:number) => (
                        <tr key={list?.managerId}>
                            <td className="check">
                                <div className="checkBox">
                                    <input type="checkbox" name="" id={list?.managerId} checked={selectManger?.includes(list?.managerId?.toString())} onChange={handleChange}/>
                                    <label htmlFor={list?.managerId}></label>
                                </div>
                            </td>
                            <td onClick={()=>onOpen(true, 'modifyEmployee', list?.managerId)}>
                                <span>{list?.managerName}</span>
                            </td>
                            <td onClick={()=>onOpen(true, 'modifyEmployee', list?.managerId)}>
                                <span>{list?.managerEmail}</span>
                            </td>
                            <td onClick={()=>onOpen(true, 'modifyEmployee', list?.managerId)}>
                                <span>{list?.managerMobile}</span>
                            </td>
                            <td>
                                <ToggleBox 
                                    name={'employee'} 
                                    id={list?.managerId} 
                                    status={list?.managerActiveStatus} 
                                    onChange={handleChangeStatus}
                                />
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}