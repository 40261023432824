import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://dotsmail.net/api/controller";

export const mailApiSlice = createApi({
  reducerPath: "mailApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 메일 보내기 전 : 보내는 사람 선택 (관리자 회사)
    getEmailPrepare : builder.mutation({
      query : (param : {managerId : any}) => {
        return{
          url : `/dotsmail/getEmailPrepare.php?managerUuid=${param.managerId}`
        }
      }
    }),
    // 회원 검색하기
    getSearchMember : builder.mutation({
      query : (param : {managerId : any, searchKeyword : string}) => {
        return{
          url : `/dotsmail/getSearchMember.php?managerId=${param.managerId}&searchKeyword=${param.searchKeyword}`
        }
      }
    }),
    // 메일 전송
    sendMail : builder.mutation({
      query : (body : any) => {
        return{
          url : '/dotsmail/sendmail.php', method : 'post', body
        }
      }
    }),
    // 메일 템플릿
    setTemplate : builder.mutation({
      query : (body : any) => {
        return{
          url : `/dotsmail/getPreviewEmail.php`, method : 'post', body
        }
      }
    }),
    // 메일 템플릿 미리보기
    getPreviewTemplate : builder.mutation({
      query : (param : {emailTemplateId : any}) => {
        return {
          url : `/dotsmail/getPreviewTemplate.php?emailTemplateId=${param.emailTemplateId}`,
        }
      }
    })
  }),
});

export const {
  useGetEmailPrepareMutation, useGetSearchMemberMutation, useSendMailMutation, useSetTemplateMutation, useGetPreviewTemplateMutation
} = mailApiSlice;