import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const MainBtnBox = () => {
    const location = useLocation()
    const pathname = location.pathname
    const navigate = useNavigate()
    return(
        <>
        <div className="btnBox">
            {pathname !== '/findId' ? <button onClick={()=>navigate('/findId')}>아이디 찾기</button> : ''}
            {pathname !== '/findPw' ? <button onClick={()=>navigate('/findPw')}>비밀번호 찾기</button> : ''}
            {pathname !== '/login' ? <button onClick={()=>navigate('/login')}>로그인</button> : ''}
            {pathname !== '/signup' ? <button onClick={()=>navigate('/signup')}>회원가입</button> : ''}
        </div>
        </>
    )
}