import React, { useState } from "react";
import { useUpdPasswordMutation } from "../../store/slice/userApiSlice";
import { useAppSelector } from "../../store/storeHooks";
interface Props {
    onClose : Function
}
export const PasswordChangePopup:React.FC<Props> = ({onClose}) => {
    const userInfo : any = useAppSelector((state)=>state.loginUser.userInfo)
    const [updPassword] = useUpdPasswordMutation()
    const [password, setPassword] = useState<any>({
        current : '',
        new1 : '', new2 : ''
    })
    const handleChange = async() => {
        if(password?.new1 !== password?.new2){
            alert('새로운 비밀번호를 다시 확인해 주세요.');return;
        }else{
            const formData = new FormData()
            formData.append('managerId', userInfo?.apiId)
            formData.append('currentPassword', password?.current)
            formData.append('newPassword', password?.new1)
            const res : any = await updPassword(formData)
            if(res.data.result === true) {
                alert('비밀번호가 성공적으로 변경되었습니다.'); onClose()
            }else{alert(res.data.resultMsg)}
        }
    }
    return(
        <>
        <div className="addPopUp popUp_active active">
            <div className="category">
                <div>
                    <div>
                        <button onClick={()=>onClose()}><i className="fa-regular fa-xmark"></i></button>
                        <h3>비밀번호 변경</h3>
                    </div>
                    <div>
                        <div>
                            <div className="inputContainer">
                                <div>
                                    <label htmlFor="test">기존 비밀번호 <span>*</span></label>
                                    <input type="password" value={password?.current} onChange={(e)=>setPassword((prev:any)=>({...prev, current : e.target.value}))} name="" id="" placeholder="비밀번호를 입력해 주세요."/>
                                </div>
                                <div>
                                    <label htmlFor="test">새 비밀번호 <span>*</span></label>
                                    <input type="password" value={password?.new1} onChange={(e)=>setPassword((prev:any)=>({...prev, new1 : e.target.value}))} name="" id="" placeholder="새 비밀번호를 입력해 주세요."/>
                                </div>
                                <div>
                                    <label htmlFor="test">새 비밀번호 확인 <span>*</span></label>
                                    <input type="password" value={password?.new2} onChange={(e)=>setPassword((prev:any)=>({...prev, new2 : e.target.value}))} name="" id="" placeholder="새 비밀번호를 입력해 주세요."/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <button className="cancleBtn" onClick={()=>onClose()}>취소</button>
                    <button className="blueBtn" onClick={()=>handleChange()}>확인</button>
                </div>
            </div>
        </div>
        </>
    )
}