import { createSlice, PayloadAction } from "@reduxjs/toolkit"
interface loginUserState {
    userInfo : loginUserInfo
}
export interface loginUserInfo {
    userId : string, account : string, name : string, isAdmin : string, apiId : string, companyId : string
}
const initUserInfio : loginUserInfo = {
    userId : '',
    account : '',
    name : '',
    isAdmin : '',
    apiId : '',
    companyId : ''
}

const initialState : loginUserState = {
    userInfo : initUserInfio
}

export const loginUserReducer = createSlice({
    name : 'loginUser',
    initialState,
    reducers : {
        setuserInfo : (state, action: PayloadAction<loginUserInfo>) => {
            state.userInfo = action.payload;
        }
    },
});

export const {setuserInfo} = loginUserReducer.actions;
export default loginUserReducer.reducer;

// dots/retentics/trunk/퍼블리싱
// dots/retentics/trunk/front