import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUserInfo, setuserInfo } from "../../../store/slice/userInfoSlice";
import { useAppDispatch, useAppSelector } from "../../../store/storeHooks";
import { PasswordChangePopup } from "../../../components/header/passwordChangePopup";

const Header_Pc = () => {
    const userInfo : loginUserInfo = useAppSelector(state=>state.loginUser.userInfo)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const handleLogOut = () => {
        dispatch(setuserInfo({userId:'', account:'',name:'',apiId:'',isAdmin:'', companyId : ''}))
        sessionStorage.removeItem('keys')
        navigate('/login')
    }
    const [isActive, setActive] = useState<boolean>(false)
    const handleClose = () => {
        setActive(false)
    }
    return(
        <>
        <header>
            <div>
                <h1>
                    <img src="/assets/images/mail_logo.png" alt="mail_logo"/>
                    <span>Mail To :)</span>
                </h1>
                <div className="rightBox">
                    <div>
                        <span>{userInfo?.name}</span>
                        <button onClick={()=>setActive(true)}>비밀번호 변경</button>
                    </div>
                    <button className="logoutBtn" onClick={handleLogOut}></button>
                </div>
            </div>
            {isActive && <PasswordChangePopup onClose={handleClose}/>}
        </header>
        
        </>
    )
}

export default Header_Pc;