import React from "react";
import { AddGroupPopup } from "../../../../components/group/popUp/AddGroupPopup";
import { useLocation, useNavigate } from "react-router-dom";
import { MemberListFilter } from "../../member/category/list-filter";
export interface Props {data : any, selectGroup : any, setSelectGroup : Function, onOpen : Function}
export const GroupList:React.FC<Props> = ({data, selectGroup, setSelectGroup, onOpen}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const category = new URLSearchParams(location.search).get('category')
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = e.target
        if(name === 'all'){
            if(data){
                if(checked){setSelectGroup(data?.map((group:any)=>group?.groupId.toString()))}
                else{setSelectGroup([])}
            }
        }else{
            if(checked){setSelectGroup((prev:any)=>[...prev, e.target.id])}
            else{setSelectGroup((prev:any)=>prev.filter((id:any)=>id!==e.target.id))}
        }
    }
    return(
        <>
        <div className="tabContentBox">
            <div className="group">
                <div className="tableWrap">
                    <table className="tableType_a">
                        <MemberListFilter
                            data={data}
                            selectedMember={selectGroup}
                            onAllCheck={handleChange}
                        />
                        <tbody>
                            {data?.map((list:any, index:number)=>(
                            <tr key={index}>
                                <td className="check">
                                    <div className="checkBox">
                                        <input 
                                            type="checkbox" 
                                            name='' 
                                            id={`${list?.groupId}`} 
                                            onChange={handleChange}
                                            checked={selectGroup.includes(list?.groupId.toString())}
                                        />
                                        <label htmlFor={`${list?.groupId}`}></label>
                                    </div>
                                </td>
                                <td onClick={()=>navigate(`/groups/addMember?category=${category}&groupId=${list?.groupId}&type=Y&page=1&size=10&keyword=column=memberName&order=asc`)}>
                                    <span>{list?.groupName}</span>
                                </td>
                                <td onClick={()=>navigate(`/groups/addMember?category=${category}&groupId=${list?.groupId}&type=Y&page=1&size=10&keyword=&column=memberName&order=asc`)}>
                                    <span>{list?.groupMembers}</span>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    )
}