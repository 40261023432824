import React from "react";
export interface Props {fileName : string, setData : Function}
export const AttachedFile:React.FC<Props> = ({fileName, setData}) => {
    const handleChange = (e:any) => {
        const {type, name, id, value} = e.target;
        if(type === 'file'){
            const reader = new FileReader();
            if(e.target.files[0]){reader.readAsDataURL(e.target.files[0])}
            reader.onload=()=>{
                setData((prev:any)=>({...prev, attachedFile : e.target.files[0], fileName : e.target.files[0].name}));
            }
        }
    }
    const handleDelFile = () => {
        setData((prev:any)=>({...prev, attachedFile : '', fileName : ''}));
    }
    return(
        <>
        <tr>
            <th>파일첨부</th>
            <td>
                <div className="attachments">
                    <div>
                        <input type="file" name="" onChange={handleChange} id="file"/>
                        <label htmlFor="file">Select a file</label>
                    </div>
                    {fileName === '' ? 
                    <span>* No file selected</span>
                    :
                    <div>
                        <span>{fileName}</span>
                        <button onClick={handleDelFile}>
                            <i className="fa-regular fa-xmark"></i>
                        </button>
                    </div>
                    }
                </div>
            </td>
        </tr>
        </>
    )
}