import React, { useState } from "react";
import { FindIdNAlert, FindIdYAlert } from "../../../components/user/popup/findId";
import { MainBtnBox } from "../mainBtnBox";
import { MainFooter } from "../mainFooter";
interface FindIdData {name : string, phone : string}
interface PopupDataType {isFind : string, userData : string}
export const FindId = () => {
    const [isPopup, setIsPopup] = useState<any>({isFind : '',  userData : ''})
    const [data, setData] = useState<FindIdData>({name : '', phone : ''})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target
        setData((prev)=>({...prev, [name] : value}))
    }
    const handleFindId = () => {
        
    }
    return(
        <>
        <div className="loginWrap find">
            <div className="loginBox">
                <div>
                    <div className="left">
                        <span>Mail To :)</span>
                    </div>
                    {/* <!-- left Fin --> */}
                    <div className="right">
                        <div>
                            <h2>아이디 찾기</h2>
                            <span>회원가입 시 등록하신 이름과 연락처를 입력해 주세요.</span>
                            <div>
                                <div className="inputBox">
                                    <label htmlFor="">이름</label>
                                    <input type="text" name="name" id="" onChange={handleChange} placeholder="이름을 입력해 주세요." required/>
                                </div>
                                <div className="inputBox">
                                    <label htmlFor="">휴대전화</label>
                                    <input type="text" name="phone" id="" onChange={handleChange} placeholder="하이픈(-)을 제외한 전화번호를 입력해 주세요." required/>
                                </div>
                                <button className="purpleBtn" onClick={handleFindId}>아이디 찾기</button>
                                <MainBtnBox/>
                            </div>
                        </div>
                    </div>
                    {/* <!-- right Fin --> */}
                </div>
                {/* <!-- flexBox Fin --> */}
                <MainFooter/>
            </div>
        </div>
        {isPopup?.isFind === 'Y' ? <FindIdYAlert/> : ''}
        {isPopup?.isFind === 'N' ? <FindIdNAlert/> : ''}
        </>
    )
}