import React, { useEffect, useLayoutEffect, useState } from "react";
import { ToastUIEditor } from "../../../components/editor/toast-ui-editor";
import { SendorBox } from "../../../components/mail/sendorBox";
import { RecevierBox } from "../../../components/mail/recevierBox";
import { TitleBox } from "../../../components/mail/titleBox";
import { AttachedFile } from "../../../components/mail/attachedFileBox";
import { TemplateBox } from "../../../components/mail/templateBox";
import { MailButtonBox } from "../../../components/mail/mailButtonBox";
import { MailSuccess } from "../../../components/mail/success/mailSuccess";
import { MailReservation } from "../../../components/mail/success/mailReservation";
import { TemplatePreviewPopup } from "../../../components/mail/popup/templatePreviewBox";
import { MailProgressPopup } from "../../../components/mail/popup/mailProgressPopup";
import { useGetEmailPrepareMutation, useSendMailMutation, useSetTemplateMutation } from "../../../store/slice/mailApiSlice";
import { useAppSelector } from "../../../store/storeHooks";
import { ReservationPopup } from "../../../components/mail/popup/reservationPopup";
import Moment from 'react-moment';
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "../../../components/spinner/spinner";
export const Mails = () => {
    const userInfo : any = useAppSelector(state=>state.loginUser.userInfo)
    const navigate = useNavigate()
    const location = useLocation()
    const [isSuccess, setSuccess] = useState<string>('')
    const [sendMail] = useSendMailMutation()
    const [setTemplate] = useSetTemplateMutation()
    const [param, setParam] = useState<any>({managerId : userInfo?.userId})
    const [popUp, setPopUp] = useState<{isOpen : boolean, type : string}>({isOpen : false, type : ''})
    const [getData, setGetData] = useState<any>({categoryList : [], managerList : [], memberList : [], sendorList : [], templateList : []})
    const [isLoading , setLoading] = useState<boolean>(false)
    const [data, setData] = useState<any>({
        reservationTime : '',
        senderId : 0, senderName : '', senderEmail : '', 
        receiveClass : 'S', receiveCategryId : 1, receiveGroupId : [], receiveMembers : [],
        title : '', attachedFile : '', fileName : '',
        templateId : 0, 
        btnClass : 'N', btnLabel : '', btnLink : '',
        contents : ''
    })
    
    // 메일 전송 전 , 모든 리스트 불러서 저장하는 상태 값
    const [getEmailPrepare] = useGetEmailPrepareMutation()
    const getPrepare = async() => {
        const result : any = await getEmailPrepare(param)
        if(result.data.result === true){
            const sendorFomatData : any = result?.data?.sendorlist?.map((list:any)=>({
                value : `${list?.sendorId},${list?.sendorName}`,
                label : list?.sendorName
            }))
            const memberFomatData : any = result?.data?.memberList?.map((list:any)=>({
                value : list?.memberId,
                label : list?.memberName
            }))
            setGetData((prev:any)=>({...prev,
            categoryList : result.data.categorylist, managerList : result?.data?.managerList, memberList : memberFomatData, 
            sendorList : sendorFomatData, templateList : result.data.templatelist}))
        }else{alert(result.data.resultMsg)}
    }
    const handleOpen = (popUp : string) => {
        setPopUp((prev) => ({...prev, isOpen : true, type : popUp}))
    }
    const handleClose = () => {
        setPopUp((prev) => ({...prev, isOpen : false, type : ''}))
    }
    const handlePreview = async(open : boolean, type : string) => {
        const formData = new FormData()
        formData.append('managerUuid', userInfo?.userId)
        formData.append('receiveMembers', data?.receiveMembers)
        formData.append('mailSubject', data?.title)
        formData.append('mailAttachedFile', data?.attachedFile)
        formData.append('mailTemplateId', data?.templateId)
        formData.append('mailBtnClass', data?.btnClass)
        formData.append('mailBtnLabel', data?.btnLabel)
        formData.append('mailBtnLink', data?.btnLink)
        formData.append('mailContents', data?.contents)
        const res : any = await setTemplate(formData)
        if(res.data.result === true) {
            setPopUp((prev:any)=>({...prev, isOpen : open, type : type}))
        }else{alert(res.data.resultMsg)}
    }
    // 메일 보내기
    const handleSubmit = async(type : string) => {
        if(!data?.title) {alert('제목을 입력해 주시기 바랍니다.'); return;}
        if(data?.receiveClass === 'S' && data?.receiveMembers?.length < 1) {
            alert("받는 사람을 선택해주세요."); return;
        }
        const formData : any = new FormData()
        // 임시저장
        if(type === 'save'){
            formData.append('mailComposeStatus', 'Y')
        }
        // 예약전송
        if(data?.reservationTime !== ''){
            formData.append('mailReserveDate', data?.reservationTime)
        }
        formData.append('managerUuid', userInfo?.userId)
        formData.append('senderId', data?.senderId)
        formData.append('senderName', data?.senderName)
        formData.append('senderEmail', data?.senderEmail)
        formData.append('receiveClass', data?.receiveClass)
        if(data?.receiveClass === 'S'){
            formData.append('receiveMembers', data?.receiveMembers)
        }
        if(data?.receiveClass === 'G'){
            formData.append('receiveCategoryId', data?.receiveCategryId)
            formData.append('receiveGroupId', data?.receiveGroupId)
        }
        formData.append('mailSubject', data?.title)
        if(data?.attachedFile){
            formData.append('mailAttachedFile', data?.attachedFile)
        }
        formData.append('mailTemplateId', data?.templateId)
        formData.append('mailBtnClass', data?.btnClass)
        if(data?.btnClass === 'Y'){
            formData.append('mailBtnLabel', data?.btnLabel)
            formData.append('mailBtnLink', data?.btnLink)
        }
        formData.append('mailContents', data?.contents)
        // 메일 전송할 때
        if(type === 'send'){
            setLoading(true)
            try{
                const result : any = await sendMail(formData)
                if(result.data.result === true){
                    setLoading(false)
                    if(data?.reservationTime === ''){
                        setSuccess('success')
                    }else{
                        setSuccess('reservation')
                    }
                }else{
                    alert(result.data.resultMsg)
                    setLoading(false)
                }
            } catch{
                setLoading(false)
                alert("전송에 실패했습니다.")
            }
            // 임시저장 할 때
        }else{
            try{
                const result : any = await sendMail(formData)
                if(result.data.result === true){
                    alert("메일이 저장되었습니다.")
                    navigate('/temporary?page=1&size=10&keyword=&column=mailSubject&order=desc')
                }else{alert(result.data.resultMsg)}
            }catch{
                alert("저장에 실패하였습니다.")
            }
        }
    }
    useEffect(()=>{
        if(userInfo?.userId)
        setParam({managerId : userInfo.userId})
    }, [userInfo])
    useEffect(()=>{
        getPrepare()
    }, [param.managerId])
    
    return(
        <>
        <div className="contentBox writingMail">
            {isSuccess === '' ?
            <div>
                <h2>Writing Mail</h2>
                
                <div className="toolBox">
                    <div className="left">
                        <button className="sendBtn" onClick={()=>handleSubmit('send')}>
                            <span>메일 보내기</span>
                        </button>
                        <button className="" onClick={()=>handleOpen('reservation')}>
                            <span>예약설정</span>
                        </button>
                        <button className="" onClick={()=>handleSubmit('save')}>
                            <span>임시저장</span>
                        </button>
                        <button className="" onClick={()=>handlePreview(true, 'template')}>
                            <span>미리보기</span>
                        </button>
                        <div>
                            {/* <span>2024.01.08.(월) 오후 5시 20분</span> */}
                            {data?.reservationTime === '' ? '' :
                            <>
                            <span className="purple">예약발송</span>
                            <span><Moment format="YYYY.MM.DD HH시 mm분">{data?.reservationTime}</Moment></span>
                            <button onClick={()=>setData((prev:any) => ({...prev, reservationTime : ''}))}>
                                <i className="fa-regular fa-xmark"></i>
                            </button>
                            </>
                            }
                        </div>
                    </div>
                    <div className="right">
                        <button className="txtBtn">임시보관 메일 5</button>
                    </div>
                </div>
                {/* <!-- toolBox Fin --> */}
                
                <div className="tableWrap">
                    <table className="tableType_b">
                        <tbody>
                            <SendorBox
                                getData={getData}
                                setData={setData}
                            />
                            <RecevierBox
                                getData={getData}
                                data={data}
                                setData={setData}
                            />
                            
                            <TitleBox
                                setData={setData}
                            />
                            <AttachedFile
                                fileName={data?.fileName}
                                setData={setData}
                            />
                            <TemplateBox
                                getData={getData?.templateList}
                                data={data?.templateId}
                                setData={setData}
                            />
                            <MailButtonBox
                                data={data}
                                setData={setData}
                            />

                            <tr className="summerNote">
                                <th>내용</th>
                                <ToastUIEditor initData="" setData={setData}/>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className="btnBox">
                    <button className="purpleBorderBtn" onClick={()=>handlePreview(true, 'template')}>미리보기</button>
                    <button className="purpleBtn" onClick={()=>handleSubmit('send')}>메일전송</button>
                </div>
            </div>
            : ''
            }

            {/* 메일 예약 발송을 위한 시간설정 팝업 */}
            {popUp.isOpen && popUp.type === 'reservation' ?
            <ReservationPopup
                setData={setData}
                onClose={handleClose}
            />
            :''
            }

            {/* <!-- 메일 전송 완료됐을 때 --> */}
            {isSuccess === 'success' ? 
            <MailSuccess
            
            />
            : ''
            }

            {/* <!-- 메일 발송 예약 완료됐을 때 --> */}
            {isSuccess === 'reservation' ? 
            <MailReservation
                reservationTime={data?.reservationTime}
            />
            : ''
            }
            
        </div>

        {/* 템플릿 미리보기 */}
        {popUp?.isOpen && popUp?.type === 'template'?
        <TemplatePreviewPopup
            onClose={handleClose}
        />
        :''
        }

        {/* 메일 전송 팝업 : 이건 필요 없을 듯 */}
        <MailProgressPopup
        
        />
        {isLoading &&
        <Spinner
        
        />
        }
        </>
    )
}