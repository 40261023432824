import React, { useRef, useState } from "react";
import { MainBtnBox } from "../mainBtnBox";
import { MainFooter } from "../mainFooter";
interface JoinData {email : string, password : string, passwordConfirm : string, name : string, phone : string}
export const SignUp = () => {
    const emailRef = useRef<any>(null)
    const pwRef = useRef<any>(null)
    const nameRef = useRef<any>(null)
    const phoneRef = useRef<any>(null)
    const scrollToInput = (ref:any) => {
        if(ref.current){ref.current.scrollIntoView({behavior : 'smooth', block : 'start'})}
    }
    const [data, setData] = useState<JoinData>({email : '', password : '', passwordConfirm : '', name : '', phone : ''})
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name , value} = e.target;
        setData((prev)=>({...prev, [name] : value}))
    }
    return(
        <>
        <div className="loginWrap signUp">
            <div className="loginBox">
                <div>
                    <div className="left">
                        <span>Mail To :)</span>
                    </div>
                    {/* <!-- left Fin --> */}
                    <div className="right">
                        <div>
                            <h2>회원가입</h2>
                            <span>서비스 이용을 위해 관리자 계정을 생성해 주세요.</span>
                            <div>
                                <div className="inputBox" ref={emailRef}>
                                    <label htmlFor="">이메일 <span>*</span></label>
                                    <input type="text" name="email" value={data?.email} placeholder="이메일을 입력해 주세요." onChange={handleChange} onFocus={()=>scrollToInput(emailRef)} required/>
                                </div>
                                <div className="inputBox" ref={pwRef}>
                                    <label htmlFor="">비밀번호 <span>*</span></label>
                                    <input type="password" name="password" value={data?.password} id="" placeholder="8~20자의 영문, 숫자, 특수문자 조합" onChange={handleChange} onFocus={()=>scrollToInput(pwRef)} required/>
                                    <input type="password" name="passwordConfirm" value={data?.passwordConfirm} id="" placeholder="비밀번호를 확인해 주세요." onChange={handleChange} onFocus={()=>scrollToInput(pwRef)} required/>
                                </div>
                                <div className="inputBox" ref={nameRef}>
                                    <label htmlFor="">이름 <span>*</span></label>
                                    <input type="text" name="name" value={data?.name} id="" placeholder="이름을 입력해 주세요." onChange={handleChange} onFocus={()=>scrollToInput(nameRef)} required/>
                                </div>
                                <div className="inputBox" ref={phoneRef}>
                                    <label htmlFor="">휴대전화</label>
                                    <input type="text" name="phone" value={data?.phone} id="" placeholder="하이픈(-)을 제외한 전화번호를 입력해 주세요." onChange={handleChange} onFocus={()=>scrollToInput(phoneRef)} required/>
                                </div>
                            </div>

                            <button className="purpleBtn">회원가입</button>
                            <MainBtnBox/>
                        </div>
                    </div>
                </div>
                <MainFooter/>
            </div>
        </div>
        </>
    )
}