import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useAppSelector } from "../storeHooks";

const baseUrl = "https://dotsmail.net/api/controller/member";
export const membersApiSlice = createApi({
  reducerPath: "membersApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 유저 리스트 가져오기
    getMemberList : builder.mutation({
      query : (param : {loginId : any, page: any, size:number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/getMemberList.php?loginId=${param.loginId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}`
        }
      }
    }),
    // 유저 저장
    setMember : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setMember.php', method : 'post', body
        }
      }
    }),
    // 유저 상세보기
    getMemberDetail : builder.mutation({
      query : (body : {memberId : any}) => {
        return{
          url : '/getMemberDetail.php', method : 'post', body
        }
      }
    }),
    // 유저 수정
    updMember : builder.mutation({
      query : (body : any) => {
        return{
          url : '/updMember.php', method : 'post', body
        }
      }
    }),
    // 유저 활성화 설정
    updMemberActiveStatus : builder.mutation({
      query : (body : {id : any, status : string}) => {
        return{
          url : '/updMemberActiveStatus.php', method : 'post', body
        }
      }
    }),
    // 회원 임시삭제
    delTempMember : builder.mutation({
      query : (body : any) => {
        return{
          url : '/delTempMember.php', method : 'post', body
        }
      }
    }),
    // 회원 완전삭제
    delMember : builder.mutation({
      query : (body : any) => {
        return{
          url : '/delMember.php', method : 'post', body
        }
      }
    }),
    // 회원 삭제 리스트
    getDelMemberList : builder.mutation({
      query : (param : {loginId : any, page: any, size:number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/delMemberList.php?loginId=${param.loginId}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}`
        }
      }
    }),
    
    // 회원 카테고리 및 그룹 리스트
    getMemberCategory : builder.mutation({
      query : (param : {loginId : any}) => {
        return{
          url : `/getMemberCategory.php?loginId=${param.loginId}`
        }
      }
    }),
    // 엑셀 파일 업로드
    getCsvFile : builder.mutation({
      query : (body : any) => {
        return{
          url : 'https://dotsmail.net/api/controller/dotsmail/getCsvFile.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetMemberListMutation, useUpdMemberActiveStatusMutation, useDelTempMemberMutation, useGetMemberCategoryMutation, useSetMemberMutation, useGetDelMemberListMutation, useDelMemberMutation, useGetMemberDetailMutation,
  useUpdMemberMutation, useGetCsvFileMutation
} = membersApiSlice;