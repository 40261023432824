import React, { useEffect, useState } from "react";
import { useGetOpenUserListMutation } from "../../../store/slice/sentmailApiSlice";
import { useAppSelector } from "../../../store/storeHooks";

interface Props {
    info : any
    onClose : Function
}
export const DisplayUserPopup:React.FC<Props> = ({info, onClose}) => {
    const managerId = useAppSelector((state) => state.loginUser.userInfo.userId)
    const [getOpenUserListApi] = useGetOpenUserListMutation()
    const [data, setData] = useState<any>([])
    const getList = async() => {
        const res : any = await getOpenUserListApi({managerUuid : managerId, mailId : info?.mailId})
        if(res.data.result === true) {
            setData(res.data)
        }else{alert(res.data.resultMsg)}
    }
    useEffect(()=>{if(info?.mailId) getList()}, [info?.mailId])
    return(
        <>
        <div className="addPopUp popUp_active active">
            <div className="openMail">
                <div>
                    <div>
                        <button><i className="fa-regular fa-xmark" onClick={()=>onClose()}></i></button>
                        <span className="">메일 제목</span>
                    </div>
                    <div>
                        <div>
                            <span><span>{data?.total_cnt}</span>명 중 <span>{data?.open_total_cnt}</span>명 읽음</span>
                        </div>
                        <ul>
                            {data?.list?.map((list:any) => (
                                <li key={list?.mailnaraId}>
                                    <span>{list?.receiverName}({list?.receiverEmail})</span>
                                    <span>{list?.openDate}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}